import {Component} from '@angular/core';
import {WebviewService} from "../shared/resources/webview/webview.service";

@Component({
  selector: 'app-tabs',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss']
})
export class layout {

  constructor(private webviewService: WebviewService) {
  }

  async goToBlog() {
    this.webviewService.openView('/blog')
  }

  async goToShop() {
    this.webviewService.openView('/shop')
  }
}
