import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {map, Observable, of} from 'rxjs';
import {AuthService} from "../../auth/shared/services/auth.service";
import {IUser} from "../../shared/resources/user/user.interface";
import {SubscriptionStatus} from "../../shared/resources/subscription/subscription-status.enum";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    // Avoid calling getMe() at every route change
    // If not initialized, call getMe(), it will set initCalled to true
    if (!this.authService.initCalled) this.authService.getMe().subscribe();
    // If initalized is true, it means we already got a response from getMe(), check user synchronously
    if (this.authService.initialized) {
      if (this.authService.user.value) {
        this.checkUser(state.url, this.authService.user.value)
        return !!this.authService.user.value;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    }
    // Else, it means getMe() got called but didn't answer yet, so we wait for an answer
    else {
      return this.authService.initializeDone.pipe(
        map(() => {
          if (this.authService.user.value) {
            this.checkUser(state.url, this.authService.user.value)
            return !!this.authService.user.value;
          } else {
            this.router.navigate(['/']);
            return false;
          }
        })
      )
    }
  }

  checkUser(url: string, user: IUser) {
    if (!user) this.router.navigate(['/']);
    if (url !== '/subscription' && user.subscriptionStatus === SubscriptionStatus.EXPIRED) {
      this.router.navigate(['/subscription']);
    }
  }

}
