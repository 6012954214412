<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="home">
      <ion-icon name="icon-home"></ion-icon>
      <ion-label>{{ 'tabs.home' | translate }}</ion-label>
    </ion-tab-button>

    <ion-tab-button [routerLink]="'/program'">
      <ion-icon name="icon-program"></ion-icon>
      <ion-label>{{ 'tabs.program' | translate }}</ion-label>
    </ion-tab-button>

    <a [routerLink]="'/gonow/home'" class="go-now-btn">
      <mbl-button type="round" color="gradient-go-now" iconStart="icon-gonow" iconStartColor="white"></mbl-button>
    </a>

    <ion-tab-button (click)="goToBlog(); $event.stopPropagation()">
      <ion-icon name="icon-lifestyle"></ion-icon>
      <ion-label>{{ 'tabs.blog' | translate }}</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="goToShop();">
      <ion-icon name="icon-shop"></ion-icon>
      <ion-label>{{ 'tabs.shop' | translate }}</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
