import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { filter } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { environment } from "../../../../environments/environment";
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.page.html',
  styleUrls: ['./new-password.page.scss'],
})
export class NewPasswordPage {


  public showFirst = false;
  public showSecond = false;

  public token: string = '';
  public successReset = false;

  constructor(
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.pipe(filter(params => params['token'])).subscribe(params => this.token = params['token']);
  }


  public redirectLogin() {
    Browser.open({url: `${environment.baseUrl}/start?step=HELP`});
  }

  public formSubmit(form: FormGroup) {
    this.authService.newPassword(this.token, form.controls['password'].value).subscribe(event => {
      this.toastService.showToaster('auth.toasts.newPassword.success', 'Connexion', 'success', 5000);
      this.successReset = true;
    }, error => {
      if(error.error.message && error.error.message === 'Invalid format') {
        form.controls['password'].setErrors({invalidFormat: true});
      }
      else {
        this.toastService.showToaster('auth.toasts.newPassword.error', 'Erreur', 'error', 5000);
      }
    });
  }

}
