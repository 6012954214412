<form *ngIf="newForm" [formGroup]="newForm" (submit)="formSubmit()">
  <div class="form">
    <p class="subtitle-medium form__subtitle">{{ 'auth.components.pwdConfirm.titleFirstInput' | translate }}</p>
    <mbl-form-input placeholder="********"
                    [type]="showFirst ? 'text' : 'password'"
                    [iconEnd]="showFirst ? 'icon-hide' : 'icon-show'"
                    formName="password"
                    [form]="newForm"
                    [withError]="false"
                    (bangIconEnd)="manageShowPassword('first', showFirst)"
                    (onFocus)="focusElement($event, 'first')"
                    (onBlur)="focusElement($event, 'first')">
    </mbl-form-input>
    <div class="conditions" *ngIf="passwordIsFocused | async">
      <p
        [ngClass]="{'invalid': newForm.controls['password'].errors && newForm.controls['password'].errors['noLength'] }">
        <ion-icon
          [name]="newForm.controls['password'].errors && newForm.controls['password'].errors['noLength'] ? 'icon-close-toat' : 'icon-validation2'"></ion-icon>
        {{ 'auth.components.pwdConfirm.conditions.length' | translate }}</p>
      <p
        [ngClass]="{'invalid': newForm.controls['password'].errors && newForm.controls['password'].errors['noUppercase'] }">
        <ion-icon
          [name]="newForm.controls['password'].errors && newForm.controls['password'].errors['noUppercase'] ? 'icon-close-toat' : 'icon-validation2'"></ion-icon>
        {{ 'auth.components.pwdConfirm.conditions.uppercase' | translate }}</p>
      <p
        [ngClass]="{'invalid': newForm.controls['password'].errors && newForm.controls['password'].errors['noNumber'] }">
        <ion-icon
          [name]="newForm.controls['password'].errors && newForm.controls['password'].errors['noNumber'] ?  'icon-close-toat' : 'icon-validation2'"></ion-icon>
        {{ 'auth.components.pwdConfirm.conditions.number' | translate }}</p>
    </div>
    <p class="subtitle-medium form__subtitle">{{ 'auth.components.pwdConfirm.titleSecondInput' | translate }}</p>
    <mbl-form-input placeholder="********"
                    [type]="showSecond ? 'text' : 'password'"
                    [iconEnd]="showSecond ? 'icon-hide' : 'icon-show'"
                    formName="confirmPassword"
                    [form]="newForm"
                    [withError]="false"
                    (bangIconEnd)="manageShowPassword('second', showSecond)"
                    (onFocus)="focusElement($event, 'second')"
                    (onBlur)="focusElement($event, 'second')">
    </mbl-form-input>
    <div class="conditions" *ngIf="confirmPasswordIsFocused | async">
      <p [ngClass]="{'invalid': newForm.errors && newForm.errors['noSame'] }">
        <ion-icon [name]="newForm.errors && newForm.errors['noSame'] ? 'icon-close-toat' : 'icon-validation2'"></ion-icon>
        {{ 'auth.components.pwdConfirm.conditions.match' | translate }}</p>
    </div>

    <p *ngIf="newForm.controls['password'].errors?.['invalidFormat']" class="invalid-format">
      <ion-icon [name]="'icon-close-toat'"></ion-icon>
      {{ 'auth.toasts.newPassword.invalidFormat' | translate }}</p>
  </div>
  <mbl-button class="form__button" [disabled]="!newForm.valid" color="black" size="full" iconEnd="icon-arrow-right"
              [iconEndSmall]="true"
              type="submit">
    {{ 'auth.components.pwdConfirm.update' | translate }}
  </mbl-button>
</form>
