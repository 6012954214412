import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofillDirective } from './autofill.directive';



@NgModule({
  declarations: [AutofillDirective],
  imports: [
    CommonModule
  ],
  exports: [AutofillDirective]
})
export class AutofillModule { }
