<div *ngIf="!inOfflineMode; else offlineVideoContainer" class="container">
  <media-player #videoElement playsinline="true" (can-play)="handleReadyToPlay($event)"
                (play)="handlePlayPause(true)"
                (pause)="handlePlayPause(false)" load="eager"
                (controls-change)="handleControlsChange($event)"
                (end)="handleEnd()"
                (error)="handleError($event)"
                stream-type="on-demand" key-disabled="true" preload="auto">
    <media-provider>
      <media-gesture event="pointerup" action="toggle:controls" data-media-gesture=""
                     style="pointer-events: none;"></media-gesture>
      <media-gesture event="dblpointerup" action="seek:-10" data-media-gesture=""
                     style="pointer-events: none;"></media-gesture>
      <media-gesture event="dblpointerup" action="seek:10" data-media-gesture=""
                     style="pointer-events: none;"></media-gesture>
    </media-provider>
    <media-controls #controls class="media-controls-container vds-controls" style="pointer-events: none;"
                    hide-delay="1500"
                    data-visible="">
      <media-controls-group class="vds-controls-group">
        <div class="media-controls">
          <div class="top-controls">
            <div class="left">
              <mbl-button type="round" [noMargin]="true" [iconOnly]="true" iconStart="icon-arrow-left"
                          iconStartColor="white"
                          class="exit-btn"
                          (bang)="handleExit()">
              </mbl-button>
            </div>
            <div *ngIf="wasStarted" class="right">
              <media-fullscreen-button *ngIf="fullScreenAvailable"></media-fullscreen-button>
              <mbl-button type="round" [noMargin]="true" [iconOnly]="true" iconStart="icon-cast"
                          *ngIf="isChromecastAvailable"
                          (bang)="requestSessionChromeCast()"
                          iconStartColor="white">
              </mbl-button>
              <mbl-button type="round" [noMargin]="true" [iconOnly]="true" iconStart="icon-airplay"
                          *ngIf="isAirplayAvailable" iconStartColor="white" (bang)="handleAirplay()">
              </mbl-button>
            </div>
          </div>
          <div *ngIf="wasStarted" class="info-video">
            <ng-container *ngIf="video?.typeOfCourse?.typeOfCourse; else videoName">
              <h3>{{ 'course-type.' + video?.typeOfCourse?.typeOfCourse | translate }}</h3>
            </ng-container>
            <ng-template #videoName>
              <h3>{{ video?.name }}</h3>
            </ng-template>
            <div class="video-context">
              <div
                class="season light">{{video?.videoType?.name || video?.season}}
              </div>
              <span>|</span>
              <div
                class="episode light">{{ 'magic-player.info.episode' | translate}}{{(video?.episode | minTwoDigits)}}
              </div>
            </div>
          </div>
          <div *ngIf="wasStarted" class="content-controls">
            <div class="main-controls">
              <media-seek-button seconds="-10">
                <ion-icon name="icon-pre10" slot="play"></ion-icon>
              </media-seek-button>
              <media-play-button>
                <ion-icon *ngIf="!isPLaying" name="icon-play-full" slot="pause"></ion-icon>
                <ion-icon *ngIf="isPLaying" name="icon-pause" slot="play"></ion-icon>
              </media-play-button>
              <media-seek-button seconds="+10">
                <ion-icon name="icon-fol10" slot="play"></ion-icon>
              </media-seek-button>
            </div>
          </div>
          <div class="bottom-controls">
            <media-time-slider *ngIf="wasStarted" class="vds-time-slider vds-slider">
              <div class="vds-slider-track"></div>
              <div class="vds-slider-track-fill vds-slider-track"></div>
              <div class="vds-slider-progress vds-slider-track"></div>

              <div class="vds-slider-thumb"></div>

              <media-slider-value type="current" format="time" slot="preview" default-appearance>
              </media-slider-value>
              <ion-icon class="pause-icon" name="icon-pause" slot="pause" color="white"></ion-icon>
              <media-time class="duration" type="duration"></media-time>
              <media-slider-preview class="vds-slider-preview">
                <media-slider-thumbnail
                  #videoThumbnail
                  slot="preview"
                  src="{{vttSrc}}"
                  class="vds-slider-thumbnail vds-thumbnail">
                </media-slider-thumbnail>
              </media-slider-preview>
            </media-time-slider>
            <div *ngIf="!inOfflineMode" class="actions">
              <mbl-button [transparent]="true" [noMargin]="true" [noPadding]="true"
                          (bang)="handlePlayFromStart()"
                          iconStart="icon-replay"
                          iconStartColor="white">
                <span>{{ 'magic-player.controls.beginning' | translate}}</span>
              </mbl-button>
              <mbl-button [transparent]="true"
                          [noMargin]="true"
                          (bang)="handleEpisodesList()"
                          [noPadding]="true"
                          iconStart="icon-list"
                          iconStartColor="white">
                <span>{{ 'magic-player.controls.episodes' | translate}}</span>
              </mbl-button>
              <mbl-button [transparent]="true" [noMargin]="true" [noPadding]="true"
                          [iconStart]="isFavorites ? 'icon-favori-full' : 'icon-favori'"
                          iconStartColor="white" (bang)="handlefavorites()">
                <span>{{ 'magic-player.controls.add-favorites' | translate}}</span>
              </mbl-button>
              <mbl-button *ngIf="!!((video$ | async)?.options?.goNow?.context !== 'GONOW_VIDEO')" [transparent]="true"
                          [noMargin]="true" [noPadding]="true" iconStart="icon-following"
                          iconStartColor="white" [disabled]="!(((typeOfCoursePlaylist$ | async)?.length || 0) > 0)"
                          (bang)="nextVideo()">
                <span>{{ 'magic-player.controls.next-episode' | translate}}</span>
              </mbl-button>
              <mbl-button *ngIf="!!((video$ | async)?.options?.goNow?.context === 'GONOW_VIDEO')" [transparent]="true"
                          [noMargin]="true" [noPadding]="true" iconStart="icon-following"
                          iconStartColor="white" [disabled]="!((video$ | async)?.options?.goNow?.hasNext)"
                          (bang)="goNowProgramNext()">
                <span>{{ 'magic-player.controls.next-episode' | translate}}</span>
              </mbl-button>
            </div>
          </div>
        </div>
      </media-controls-group>
    </media-controls>
  </media-player>
  <div class="bottom-right-controls"
       *ngIf="!showControls && (video$ | async)?.options?.goNow?.context === 'GONOW_WARMUP' && !displayNext">
    <mbl-button type="secondary" color="white" [noMargin]="true" (bang)="handleEnd({ skip: true })">
      <span>{{ 'magic-player.controls.skip-warmup' | translate}}</span>
    </mbl-button>
  </div>
  <div class="bottom-right-controls"
       *ngIf="!showControls && displayNext && ((typeOfCoursePlaylist$ | async)?.length || 0) > 0 && !!!((video$ | async)?.options?.goNow)">
    <mbl-button type="secondary" color="white" [noMargin]="true" (bang)="nextVideo()">
      <span>{{ 'magic-player.controls.next-episode' | translate}}</span>
    </mbl-button>
  </div>
  <div class="bottom-right-controls"
       *ngIf="!showControls && displayNext && !!((video$ | async)?.options?.goNow?.hasNext)">
    <mbl-button *ngIf="(video$ | async) as video" type="secondary" color="white" [noMargin]="true"
                (bang)="goNowProgramNext()">
      <span>{{ 'magic-player.controls.next-episode' | translate}}</span>
    </mbl-button>
  </div>
  <app-loader [loading]="isLoading"></app-loader>
</div>
<ng-template #offlineVideoContainer>
  <div class="offline-video-container">
    <video (fullscreenchange)="onFullScreenChange($event)" #offlineVideo></video>
  </div>
</ng-template>
