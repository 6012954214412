import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StickyHeaderComponent} from "./sticky-header.component";
import {ButtonModule} from "../../button/button.module";
import {ProfilePictureModule} from "../../profile-picture/profile-picture.module";
import {IonicModule} from "@ionic/angular";
import {GenericHeaderModule} from '../generic-header/generic-header.module';
import {RouterModule} from "@angular/router";
import { LoaderComponent } from '../../loader/loader.component';


@NgModule({
  declarations: [StickyHeaderComponent],
  imports: [
    CommonModule,
    LoaderComponent,
    IonicModule,
    ButtonModule,
    ProfilePictureModule,
    GenericHeaderModule,
    RouterModule
  ],
  exports: [StickyHeaderComponent]
})
export class StickyHeaderModule {
}
