import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function createPasswordStrengthValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

      const value = control.value;
      const noUppercase = !(/[A-Z]+/.test(value));
      const noNumber = !(/[0-9]+/.test(value));
      const noLength = value.length < 8;

      if(noUppercase || noNumber || noLength){
        return {
          noUppercase,
          noNumber,
          noLength
        }
      }
      return null;
    }
  }