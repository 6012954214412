import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  public token: Subject<string> = new Subject<string>();

  constructor() {
  }

  signOut(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  public saveToken(token: string, storage: 'localStorage' | 'sessionStorage' = 'localStorage'): void {
    const store = storage === 'localStorage' ? window.localStorage : window.sessionStorage;
    store.removeItem(TOKEN_KEY);
    store.setItem(TOKEN_KEY, token);
    this.token?.next(token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY) || window.localStorage.getItem(TOKEN_KEY);
  }

  public saveRefreshToken(token: string, storage: 'localStorage' | 'sessionStorage' = 'localStorage'): void {
    const store = storage === 'localStorage' ? window.localStorage : window.sessionStorage;
    store.removeItem(REFRESHTOKEN_KEY);
    store.setItem(REFRESHTOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return window.sessionStorage.getItem(REFRESHTOKEN_KEY) || window.localStorage.getItem(REFRESHTOKEN_KEY);
  }

}
