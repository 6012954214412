import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = [];
  public loading = new BehaviorSubject(false)

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/layout/home') this.loading.next(true)
      }
      if (event instanceof NavigationEnd) {
        if (event.url === '/layout/home') this.loading.next(false)
        if (this.history.length > 10) this.history.shift();
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(fallback: string): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(fallback || "/layout/home");
    }
  }

  home() {
    this.router.navigateByUrl("/layout/home");
  }

}
