import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'mbl-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit {
  @ViewChild('formInput') formInput!: ElementRef;

  @Input() autocompleteType?: string; // "email", "password"
  @Input() placeholder?: string;
  @Input() type?: string; // text (default), number, password, email, tel
  @Input() iconStart?: string; // use icon defined in assets/icon/mbl
  @Input() iconEnd?: string; // use icon defined in assets/icon/mbl
  @Input() formName: string = '';
  @Input() form: FormGroup = new FormGroup({});
  @Input() withError: boolean = true;

  @Output() onFocus: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onBlur: EventEmitter<Event> = new EventEmitter<Event>();

  disableErrors: boolean = false;

  @Output('bangIconEnd') bangIconEnd: EventEmitter<void> =
    new EventEmitter<void>();

  constructor() {}

  ngOnInit() {
    this.disableErrors = this.form.controls[this.formName].valid;
  }

  handleFocus(event: Event) {
    this.disableErrors = true;
    this.onFocus.emit(event);
  }

  handleBlur(event: Event) {
    this.disableErrors = this.form.controls[this.formName].valid;
    this.onBlur.emit(event);
  }

  keypress(event: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      Keyboard.hide();
    }
  }
}
