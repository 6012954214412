import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IVideo} from "../../resources/video/video.app.interface";
import {MagicPlayerService} from "./shared/magic-player.service";
import {Platform} from "@ionic/angular";
import {VideoService} from "../../resources/video/video.service";
import {PlaylistTypeEnum} from "../../resources/video/playlist-type.enum";
import {IMagicPlayerVideo} from "./shared/magic-player-video.interace";
import {OrientationType, ScreenOrientation} from '@capawesome/capacitor-screen-orientation';
import {IVideoPlaylistItem} from "../../resources/playlist/video-playlist-item.app.interface";
import {IVideoPlaylist} from "../../resources/playlist/video-playlist.interface";
import {convertDurationInMinutesIntoSeconds} from "../../utils/convertor.util";
import {IMagicPlayerOptions} from './shared/magic-player-options.interface';
import {timeStringToSeconds} from 'src/app/pages/menu-pages/program/shared/utils';
import {Subscription} from 'rxjs';
import 'vidstack/player';
import 'vidstack/player/layouts';
import 'vidstack/player/ui';
import {
  defineCustomElement,
  MediaControlsElement,
  MediaGestureElement,
  MediaPlayButtonElement,
  MediaPlayerElement,
  MediaProviderElement,
  MediaThumbnailElement,
  MediaTimeSliderElement
} from "vidstack/elements";
import {ConnectionStatus} from "../../services/network.service";
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'app-magic-player',
  templateUrl: './magic-player.component.html',
  styleUrls: [
    './magic-player.component.scss',
    './magic-player.component.mobile.scss'
  ],
})
export class MagicPlayerComponent implements OnDestroy {

  connectionStatus = ConnectionStatus;

  @ViewChild('videoElement') videoElement?: ElementRef;
  @ViewChild('videoThumbnail') videoThumbnail?: ElementRef;
  @ViewChild('controls') controls?: ElementRef;

  @ViewChild('offlineVideo') offlineVideo?: ElementRef;

  video$ = this.magicPlayerService.videoToPlay$;
  video: IVideo | undefined;
  vttSrc: string = '';
  hasNext: boolean = false;
  fullScreenAvailable: boolean = false;
  isFavorites: boolean = false;
  showControls: boolean = false;
  fragNb: number = 0;
  itsReadyToPlay: boolean = false;
  isPLaying: boolean = false;
  wasStarted: boolean = false;
  isLoading: boolean = false;
  isAirplayAvailable: boolean = false;
  isChromecastAvailable: boolean = false;

  lastWatchTime: number = 0;
  displayNext: boolean = false;

  pauseTimeGuard: boolean = true;

  chromeCast: any = null;
  chromeCastApiConfig: any = null;
  chromeCastSession: any = null;
  chromeCastMedia: any = null;

  inOfflineMode = false;

  typeOfCoursePlaylist$ = this.magicPlayerService.typeOfCoursePlaylist$;

  videoSubscription?: Subscription

  constructor(private magicPlayerService: MagicPlayerService,
              private toastService: ToastService,
              private readonly platform: Platform,
              private cdRef: ChangeDetectorRef,
              private videoService: VideoService) {
    console.log('magic player constructor');
  }

  ngOnDestroy(): void {
    this.unlockScreen()
    this.videoSubscription?.unsubscribe();
  }

  init(inOfflineMode: boolean) {
    if (!inOfflineMode) {
      defineCustomElement(MediaPlayerElement);
      defineCustomElement(MediaProviderElement);
      defineCustomElement(MediaGestureElement);
      defineCustomElement(MediaControlsElement);
      defineCustomElement(MediaTimeSliderElement);
      defineCustomElement(MediaPlayButtonElement);
      defineCustomElement(MediaThumbnailElement);
      if (this.platform.is('desktop')) {
        this.fullScreenAvailable = true;
        // @ts-ignore
        window['__onGCastApiAvailable'] = (isAvailable: boolean, err: any) => {
          if (isAvailable) {
            this.initChromeCast();
          }
        };
      }
      if (this.platform.is('ios')) {
        this.isAirplayAvailable = true;
      }

      this.platform.ready().then(() => {
        console.log('the platform is ready');
        this.initChromeCast();
      });
    }
  }

  ngAfterViewInit() {
    this.lockScreen();
    this.videoSubscription?.unsubscribe();
    console.log('ngAfterViweInit')
    console.log(this.video$.value)
    this.videoSubscription = this.video$?.subscribe((magicVideo: IMagicPlayerVideo | null) => {
      this.inOfflineMode = magicVideo?.options.inOfflineMode || false;
      this.init(this.inOfflineMode);
      console.debug('subscribe to video$')
      console.log('magic video', magicVideo)
      this.isLoading = true;
      this.cdRef.detectChanges();
      if (magicVideo?.video) {
        this.video = magicVideo.video;
        this.hasNext = !!magicVideo.options.hasNext;
        this.resetVideo(magicVideo.options.inOfflineMode);
        if (!this.inOfflineMode && this.videoElement) {
          this.videoElement.nativeElement.currentTime = 0;
          this.videoElement.nativeElement.setAttribute('src', this.video.url);
          this.videoElement.nativeElement.setAttribute('poster', this.video.thumbnailUrl);
          this.loadVttFile(this.video.id);
          this.videoElement?.nativeElement.addEventListener('time-update', (data: any) => {
            const seconds = Math.floor(data.detail.currentTime);

            if (this.video?.videoDurationInMinutes) {
              this.displayNext = (convertDurationInMinutesIntoSeconds(this.video?.videoDurationInMinutes) - seconds) <= 10;
            }

            if (seconds % 5 === 0 && this.lastWatchTime !== seconds) {
              console.log('time-update', data.detail.currentTime);
              this.lastWatchTime = seconds;
              this.magicPlayerService.updateWatchedTime(data.detail.currentTime);
            }
          });
          this.videoService.getPlaylist(PlaylistTypeEnum.VIDEOPLAYLIST_MYFAVOURITES)
            .subscribe((playlist: IVideoPlaylist) => {
              playlist.videoPlaylistItems.find((v: IVideoPlaylistItem) => v.video.id === this.video?.id) ? this.isFavorites = true : this.isFavorites = false;
            });
        } else {
          if (this.video?.file) {
            this.loadOfflineVideo(this.video);
          }
        }
      } else {
        this.resetVideo();
      }
    });
  }

  loadOfflineVideo(video: IVideo) {
    console.log("load offline")
    if (this.offlineVideo && video.file) {
      this.offlineVideo.nativeElement.setAttribute('src', '');
      setTimeout(() => {
        if (this.offlineVideo) {
          const videoEl = this.offlineVideo.nativeElement;
          videoEl.setAttribute('src', video.file)
          videoEl.play().then(() => {
            videoEl.requestFullscreen();
          });
        }
      }, 1000);
    }
  }

  onFullScreenChange(value: any) {
    console.log(this.offlineVideo?.nativeElement.webkitDisplayingFullscreen)
    if (!this.offlineVideo?.nativeElement.webkitDisplayingFullscreen) {
      this.offlineVideo?.nativeElement.pause();
      this.magicPlayerService.stopVideo();
      this.magicPlayerService.playerExit.emit();
    }
  }

  loadVttFile(videoId: string) {
    this.videoService.getVttFile(videoId).subscribe({
      next: (vtt) => {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        this.videoThumbnail?.nativeElement.setAttribute('src', event!.target!.result)
      });
      reader.readAsDataURL(new Blob([vtt.replace(/jpg/g, 'jpg#xywh=140,80,284,160')], {type: 'text/vtt'}));
    }, error: (err) => {
      this.toastService.showToaster('Erreur lors du chargement la vidéo', 'Erreur', 'error', 5000)
      this.handleExit('error')
    }});
  }

  handleError(event: Event) {
    console.log(event)
  }

  handleReadyToPlay(event: Event) {
    if (event.returnValue && this.videoElement && !this.inOfflineMode) {
      console.log('ready to play');
      this.itsReadyToPlay = true;
      const childVideo = this.videoElement?.nativeElement.querySelector('video');
      if (this.video$.value?.options.startAt) this.videoElement.nativeElement.currentTime = this.video$.value?.options.startAt;
      childVideo.onprogress = (e: any) => {
        this.fragNb++;
        if (!this.wasStarted) {
          this.videoElement?.nativeElement.play().then(() => {
            this.isLoading = false;
            this.pauseTimeGuard = false;
            this.wasStarted = true;
            console.log('play');
          });
        }
      }
    }
  }

  handleControlsChange(event: any) {
    this.showControls = event.detail;
  }

  handleEpisodesList() {
    this.magicPlayerService.goToEpisodesList();
  }

  handlePlayPause(isPLaying: boolean) {
    this.isPLaying = isPLaying;
    this.showControls = !this.isPLaying;
    console.log('play pause', this.chromeCastSession);
    /*if (this.chromeCastMedia) {
      if (this.isPLaying) {
        this.playMediaChromeCast();
      } else {
        this.pauseMediaChromeCast();
      }
    }*/
  }

  handleExit(context = 'exit') {
    if (this.videoElement) {
      this.videoElement.nativeElement.pause().then(() => {
        if (this.chromeCastMedia) {
          this.stopSessionChromeCast();
        }
        this.unlockScreen();
        this.magicPlayerService.stopVideo();
      });

      if (this.video && context === 'exit') {
        const durationInSeconds = timeStringToSeconds(this.video?.videoDurationInMinutes);
        const watchedTimeInSeconds = this.videoElement.nativeElement.currentTime;
        const secondsLeft = durationInSeconds - watchedTimeInSeconds;
        if (secondsLeft < 40 && this.magicPlayerService.videoToPlay$.value) {
          this.magicPlayerService.updateWatchedTime(durationInSeconds)
          this.magicPlayerService.videoEnded.emit(this.magicPlayerService.videoToPlay$.value);
        }
      }

      this.magicPlayerService.playerExit.emit()
    }
  }

  handlefavorites() {
    if (this.video) {
      if (!this.isFavorites) {
        this.videoService.addVideoToPlaylist(this.video.id, PlaylistTypeEnum.VIDEOPLAYLIST_MYFAVOURITES).subscribe((res) => {
          this.isFavorites = true;
        });
      } else {
        this.videoService.removeVideoFromPlaylist(this.video.id, PlaylistTypeEnum.VIDEOPLAYLIST_MYFAVOURITES).subscribe((res) => {
          this.isFavorites = false;
        });
      }
    }
  }

  handlePlayFromStart() {
    if (this.videoElement) {
      this.videoElement.nativeElement.pause().then(() => {
        if (this.videoElement) {
          this.videoElement.nativeElement.currentTime = 0;
          this.videoElement.nativeElement.play().then(() => {
            this.showControls = false;
          });
        }
      });
    }
  }

  handleTap() {
    if (this.videoElement && !this.pauseTimeGuard) {
      this.pauseTimeGuard = true;
      this.videoElement.nativeElement.pause().then(() => {
        this.showControls = true;
        setTimeout(() => {
          this.pauseTimeGuard = false;
        }, 500)
      });
    }
  }

  handleAirplay() {
    if (this.platform.is('ios')) {
      const childVideo = this.videoElement?.nativeElement.querySelector('video');
      childVideo.webkitShowPlaybackTargetPicker();
    }
  }

  handleEnd(_args?: { skip?: boolean }) {
    const defaultArgs = {skip: false};
    const args = {...defaultArgs, ..._args};

    if (this.video?.videoDurationInMinutes && !args.skip) {
      this.magicPlayerService.updateWatchedTime(timeStringToSeconds(this.video?.videoDurationInMinutes))
    }

    if (this.magicPlayerService.videoToPlay$.value?.options.goNow?.context === 'GONOW_WARMUP' && this.magicPlayerService.afterWarmup) {
      const options: IMagicPlayerOptions = {
        ...this.magicPlayerService.videoToPlay$.value?.options,
        startAt: this.magicPlayerService.videoToPlay$.value?.options.goNow.startVideoAfterWarmupAt || 0,
        goNow: {...this.magicPlayerService.videoToPlay$.value?.options.goNow, context: 'GONOW_VIDEO'}
      }
      this.magicPlayerService.playVideo(this.magicPlayerService.afterWarmup, options)
      this.magicPlayerService.afterWarmup = null;
    } else if (this.magicPlayerService.videoToPlay$.value?.options.goNow?.context === 'GONOW_VIDEO') {
      this.magicPlayerService.videoEnded.emit(this.magicPlayerService.videoToPlay$.value);
      if (this.magicPlayerService.videoToPlay$.value?.options.goNow?.context === 'GONOW_VIDEO') this.handleExit('end');
    } else if (this.hasNext) {
      this.nextVideo();
    }
  }

  initChromeCast() {
    if (!this.inOfflineMode) {
      console.log('init chrome cast', window.chrome);
      this.chromeCast = window.chrome;
      let appId = this.chromeCast.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;
      this.chromeCastApiConfig = new this.chromeCast.cast.ApiConfig(new this.chromeCast.cast.SessionRequest(appId), (session: any) => {
        console.log('session listener', session);
      }, (receiverAvailable: any) => {
      });

      this.chromeCast.cast.initialize(this.chromeCastApiConfig, () => {
        this.isChromecastAvailable = true;
      }, function (err: any) {
        console.log(err);
      });
    }
  }

  requestSessionChromeCast() {
    this.chromeCast.cast.requestSession((session: any) => {
      this.chromeCastSession = session;
      console.log('session', session);
      this.chromeCastSession.addUpdateListener((isAlive: any) => {
        console.log('isAlive', isAlive);
      });
      this.loadMediaChromeCast();
    }, (err: any) => {
      console.log(err);
    });
  }

  loadMediaChromeCast() {
    const mediaInfo = new this.chromeCast.cast.media.MediaInfo(this.video?.url, 'video/mp4');
    this.chromeCastSession.loadMedia(new this.chromeCast.cast.media.LoadRequest(mediaInfo), (media: any) => {
      this.chromeCastMedia = media;

      this.chromeCastMedia.addMediaListener((event: any) => {
        console.log('media event', event);
      });
    }, (err: any) => {
      console.log(err);
    });
  }

  playMediaChromeCast() {
    this.chromeCastMedia.play({}, () => {
    }, (err: any) => {
      console.log(err);
    });
  }

  pauseMediaChromeCast() {
    this.chromeCastMedia.pause({}, () => {
      console.log('media paused');
    }, (err: any) => {
      console.log(err);
    });
  }

  stopSessionChromeCast() {
    this.chromeCastSession.stop(() => {
      console.log('session stopped');
    }, (err: any) => {
      console.log(err);
    });
  }

  nextVideo() {
    this.magicPlayerService.nextVideo();
  }

  goNowProgramNext() {
    if (this.video) {
      const durationInSeconds = timeStringToSeconds(this.video?.videoDurationInMinutes);
      const watchedTimeInSeconds = this.videoElement?.nativeElement.currentTime;
      const secondsLeft = durationInSeconds - watchedTimeInSeconds;
      if (this.magicPlayerService.videoToPlay$.value)
        this.magicPlayerService.updateWatchedTime(durationInSeconds)
      setTimeout(() => {
        this.magicPlayerService.videoToPlay$.value?.options.goNow?.next?.()
      }, 300)
    }

  }

  lockScreen = async () => {
    if (true) { //if (!this.inOfflineMode) {
      try {
        if (!this.platform.is('desktop')) {
          await ScreenOrientation.lock({type: OrientationType.LANDSCAPE});
        }
      } catch (e) {
        console.debug('Lock screen not supported', e)
      }
    }
  };

  unlockScreen = async () => {
    if (true) {
      try {
        if (!this.platform.is('desktop') && this.platform.is('tablet')) {
          await ScreenOrientation.unlock();
        }
        if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
          await ScreenOrientation.lock({type: OrientationType.PORTRAIT});
        }
      } catch (e) {
        console.debug('Lock screen not supported', e)
      }
    }
  };

  private resetVideo(isOffline?: boolean) {
    if (!isOffline && this.videoElement && this.videoThumbnail) {
      this.showControls = false;
      this.videoElement.nativeElement.setAttribute('src', '');
      this.videoElement.nativeElement.setAttribute('poster', '');
      this.videoThumbnail.nativeElement.setAttribute('src', '')
      this.fragNb = 0;
      this.pauseTimeGuard = true;
      this.wasStarted = false;
      this.displayNext = false;
    } else if (this.offlineVideo) {
      this.offlineVideo.nativeElement.setAttribute('src', '')
    }
  }
}
