import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'mbl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  host: {
    '[class.fitContent]': 'fitContent',
  },
})
export class ButtonComponent implements OnInit {

  @Input() color?: string; // use colors defined in variables.scss
  @Input() isOnThumb?: boolean;
  @Input() darkThumb?: boolean;
  @Input() type?: string; // primary (default), secondary, tertiary, round, square
  @Input() size?: string; // normal (default), full (for all types except round), small (for round only)
  @Input() iconStart?: string; // use icon defined in assets/icon/mbl and iconStart is used for round icon
  @Input() iconStartColor?: string; // use colors defined in variables.scss
  @Input() iconStartSmall?: boolean;
  @Input() iconEnd?: string; // use icon defined in assets/icon/mbl
  @Input() iconEndColor?: string; // use colors defined in variables.scss
  @Input() iconEndSmall?: boolean;
  @Input() iconOnly?: boolean; // only for round type
  @Input() isContentSpaced?: boolean;
  @Input() disabled?: boolean;
  @Input() transparent?: boolean;
  @Input() noMargin?: boolean;
  @Input() noPadding?: boolean;
  @Input() isLoading?: boolean;
  @Input() fitContent?: boolean;
  @Input() maxWidth?: boolean;

  @Output('bang') bang: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

}
