import {NgModule} from '@angular/core';
import { TraductionGenderPipe } from './traduction-gender.pipe';

@NgModule({
  declarations: [TraductionGenderPipe],
  exports: [
    TraductionGenderPipe
  ]
})
export class TraductionGenderModule {
}
