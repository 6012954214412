<ion-button [disabled]="disabled || isLoading"
            [style.maxWidth]="maxWidth ? '500px' : 'initial'"
            [color]="color"
            (click)="bang.emit()"
            [type]="type"
            [ngClass]="{'secondary': type === 'secondary',
'tertiary': type === 'tertiary',
'round': type === 'round',
'square': type === 'square',
'transparent': iconOnly || transparent,
'no-padding': noPadding,
'small': (size === 'small' && type === 'round'),
'full': (size === 'full' && type !== 'round'),
'no-margin': noMargin,
'on-thumb': isOnThumb,
'on-thumb__dark': darkThumb,
'spaced-content': isContentSpaced}"
            class="secondary full">
  <ion-icon *ngIf="iconStart" [slot]="((type === 'round') || (type === 'square')) ? 'icon-only' : 'start'"
            [name]="iconStart"
            [color]="iconStartColor"
            [ngClass]="{'icon-small': iconStartSmall}"></ion-icon>
  <div class="button-container">
    <ng-content *ngIf="(type !== 'round') && (type !== 'square')"></ng-content>
  </div>
  <ion-icon *ngIf="iconEnd && (type !== 'round') && (type !== 'square')"
            slot="end"
            [name]="iconEnd"
            [color]="iconEndColor"
            [ngClass]="{'icon-small': iconEndSmall}"></ion-icon>
  <div *ngIf="isLoading" class="loader-container">
    <app-loader [loading]="isLoading"></app-loader>
  </div>
</ion-button>
