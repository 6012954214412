import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectComponent} from "./select.component";
import {IonicModule} from "@ionic/angular";
import { SharedModule } from '../../shared.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [SelectComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule
  ],
  exports: [
    SelectComponent,
    IonicModule
  ]
})
export class SelectModule {
}
