import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';


@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {

  public store: Storage = {} as Storage

  constructor(private readonly storage: Storage) {
  }

  public initializeDbLocal() {
    return this.storage
      .create()
      .then(db => {
        this.store = db;
        return db;
      });
  }

  public getStore(): Storage {
    return this.store;
  }

  public set(key: string, value: any) {
    return this.store.set(key, value);
  }

  public get(key: string) {
    return this.store.get(key);
  }

  public remove(key: string) {
    return this.store.remove(key);
  }

  public clear() {
    return this.store.clear();
  }
}
