import { IVideoPlaylist } from "src/app/shared/resources/playlist/video-playlist.interface";

export function timeStringToSeconds(time: string): number {
    if (time === '-') return 0;
    const [hours, minutes, seconds] = time.split(':');
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
}

export function getCurrentProgressIndex(videoPlaylist: IVideoPlaylist) {
    for(let i = 1; i < videoPlaylist.videoPlaylistItems.length; i++) {
        if (timeStringToSeconds(videoPlaylist.videoPlaylistItems[i].video.videoDurationInMinutes) > videoPlaylist.videoPlaylistItems[i].watchTime + 30) {
            return i - 1;
        }
    }
    return videoPlaylist.videoPlaylistItems.length - 1;
}
