import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from "./shared/shared.module";
import {CoreModule} from "./core/core.module";
import {TranslateService} from "@ngx-translate/core";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutRoutingModule} from './layout/layout-routing.module';
import {IonicStorageModule} from '@ionic/storage-angular';
import {Drivers} from '@ionic/storage';
import {ToastModule} from "./shared/components/toast/toast.module";
import {NewPasswordPageModule} from './auth/pages/new-password/new-password.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './auth/shared/interceptors/jwt.interceptor';
import {MagicPlayerModule} from "./shared/components/magic-player/magic-player.module";
import { NavigationService } from './shared/services/navigation.service';
import {Calendar} from "@awesome-cordova-plugins/calendar/ngx";

@NgModule({
  declarations: [AppComponent],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: () => () => null, deps: [NavigationService], multi: true},
    Calendar
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      _forceStatusbarPadding: true
    }),
    IonicStorageModule.forRoot({
      name: '_mbl',
      storeName: '_default',
      driverOrder: [Drivers.IndexedDB],
    }),
    AppRoutingModule,
    LayoutRoutingModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastModule,
    NewPasswordPageModule,
    MagicPlayerModule
  ],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('fr');
    translate.use('fr');
  }
}
