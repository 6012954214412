import {Injectable} from '@angular/core';
import {WebViewComponent} from "../../../pages/menu-pages/web-view/web-view.component";
import {Browser} from "@capacitor/browser";
import {AuthService} from "../../../auth/shared/services/auth.service";
import {ModalController, Platform} from "@ionic/angular";
import { environment } from 'src/environments/environment';
import { Keyboard } from '@capacitor/keyboard';
import { KeyboardService } from '../keyboard.service';

@Injectable({
  providedIn: 'root'
})
export class WebviewService {

  modal?: HTMLIonModalElement;
  constructor(private authService: AuthService, private readonly platform: Platform, private modalCtrl: ModalController) {
  }

  openView(shopUrl: string, cb = () => {}) {
    this.authService.generateAuthorizedUrl(shopUrl).subscribe(async url => {
      if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
        if(this.modal) return;
        this.modal = await this.modalCtrl.create({
          component: WebViewComponent,
          componentProps: {url: url.toString()},
          canDismiss: true,
          handleBehavior: 'cycle',
          cssClass: 'modal auto-height full',
          handle: false,
          initialBreakpoint: 1,
          breakpoints: [0, 1]
        });
        await this.modal.present();
        this.modal.onWillDismiss().then(() => {
          try { Keyboard.hide() }
          catch(err) { }
          cb()
          this.modal = undefined
        })
      } else {
        await Browser.open({url: url.toString()})
      }
    })
  }

  async openViewNotLogged(shopUrl: string, cb = () => {}) {
      if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
        this.modal = await this.modalCtrl.create({
          component: WebViewComponent,
          componentProps: {url: environment.shopUrl + shopUrl},
          canDismiss: true,
          handleBehavior: 'cycle',
          cssClass: 'modal auto-height full',
          handle: false,
          initialBreakpoint: 1,
          breakpoints: [0, 1]
        });
        await this.modal.present();
        this.modal.onWillDismiss().then(() => {
          try { Keyboard.hide() }
          catch(err) { }
          cb()
          this.modal = undefined
        })
      } else {
        await Browser.open({url: environment.shopUrl + shopUrl})
      }
  }
}
