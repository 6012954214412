import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, tap } from "rxjs";
import { AuthService } from 'src/app/auth/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { IVideoPlaylist } from "../playlist/video-playlist.interface";
import { PlaylistTypeEnum } from "../video/playlist-type.enum";
import { IVideoOffline } from "../video/video-offline.interface";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public playlistMyList$: BehaviorSubject<IVideoPlaylist | undefined> = new BehaviorSubject<IVideoPlaylist | undefined>(undefined);
  public playlistFavourites$: BehaviorSubject<IVideoPlaylist | undefined> = new BehaviorSubject<IVideoPlaylist | undefined>(undefined);
  public playlistInProgress$: BehaviorSubject<IVideoPlaylist | undefined> = new BehaviorSubject<IVideoPlaylist | undefined>(undefined);
  public playlistDownloaded$: Subject<IVideoOffline[] | undefined> = new Subject<IVideoOffline[] | undefined>();
  profilePictureLoading$ = new BehaviorSubject<boolean>(false);
  

  constructor(private http: HttpClient, private authService: AuthService) {}

  updatePlaylist(type: PlaylistTypeEnum, playlist: IVideoPlaylist) {
    if (type === PlaylistTypeEnum.VIDEOPLAYLIST_MYLIST) {
      this.playlistMyList$.next(playlist);
    }

    if (type === PlaylistTypeEnum.VIDEOPLAYLIST_MYFAVOURITES) {
      this.playlistFavourites$.next(playlist);
    }

    if (type === PlaylistTypeEnum.VIDEOPLAYLIST_CONTINUE) {
      this.playlistInProgress$.next(playlist);
    }
  }

  updateDownloadedPlaylist(videos: IVideoOffline[]) {
      this.playlistDownloaded$.next(videos);
  }

  uploadProfilePicture(file: File) {
    let formData: FormData = new FormData();
    formData.append('avatarFile', file);
    this.profilePictureLoading$.next(true);
    this.http.post(`${environment.apiUrl}/api/user/avatar`, formData).subscribe(() => {
      this.authService.getMe().subscribe(() => {
        this.profilePictureLoading$.next(false);
      }, () => {
        this.profilePictureLoading$.next(false);
      })
    }, () => {
      this.profilePictureLoading$.next(false);
    });
  }

  removeProfilePicture() {
    this.profilePictureLoading$.next(true);
    this.http.delete(`${environment.apiUrl}/api/users/avatar`).subscribe(() => {
      this.authService.getMe().subscribe(() => {
        this.profilePictureLoading$.next(false);
      })
    }, () => {
      this.profilePictureLoading$.next(false);
    });
  }

  updateUser(value: any) {
    return this.http.put(`${environment.apiUrl}/api/users/${this.authService.user.value.id}`, value).pipe(
      tap(() => this.authService.getMe().subscribe())
    );
  }
}
