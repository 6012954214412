import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IVideo} from "../../../resources/video/video.app.interface";
import {IMagicPlayerVideo} from "./magic-player-video.interace";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {IMagicPlayerOptions} from "./magic-player-options.interface";
import {ContextEnum} from "../../../resources/video/context.enum";
import {IVideoPlaylistItem} from "../../../resources/playlist/video-playlist-item.app.interface";
import {Router} from "@angular/router";
import {PlaylistTypeEnum} from "../../../resources/video/playlist-type.enum";
import {TrainingService} from 'src/app/shared/resources/training/training.service';

@Injectable({
  providedIn: 'root'
})
export class MagicPlayerService {

  private videoToPlay: IMagicPlayerVideo | null = null;
  public afterWarmup: IVideo | null = null;
  public videoToPlay$: BehaviorSubject<IMagicPlayerVideo | null> = new BehaviorSubject<IMagicPlayerVideo | null>(null);
  public typeOfCoursePlaylist$ = new BehaviorSubject<IVideoPlaylistItem[]>([])

  private playlist: IVideoPlaylistItem[] | null = null;
  private playlistOptions: IMagicPlayerOptions | null = null;

  public videoEnded = new EventEmitter<IMagicPlayerVideo>();
  public playerExit = new EventEmitter();
  public watchTimeUpdated = new EventEmitter<{ video: IVideo, watchTime: number }>();

  constructor(private http: HttpClient, private router: Router, private trainingService: TrainingService) {
  }

  public playPlaylist(playlist: IVideoPlaylistItem[], video: IVideo, options: IMagicPlayerOptions) {
    this.playlist = playlist;
    this.playlistOptions = options;
    this.playVideo(video, {
      ...options,
      hasNext: this.checkIfNextVideo(video.id)
    });
  }

  public playVideo(video: IVideo, options: IMagicPlayerOptions = {
    context: ContextEnum.default,
  }) {
    this.videoToPlay = {
      video,
      options
    };
    this.videoToPlay$.next(this.videoToPlay);
    if(options.fetchTypeOfCourse !== false) {
      this.getSameTypeOfCourseVideos()
    }
  }

  playVideoWithWarmup(video: IVideo, options: IMagicPlayerOptions = {
    context: ContextEnum.default
  }) {
    if(options.goNow?.withWarmup) {
      this.afterWarmup = video;
      this.videoToPlay = { video: options.goNow.withWarmup, options: { ...options, startAt: 0, goNow: {...options.goNow, context: 'GONOW_WARMUP' }} };
      this.videoToPlay$.next(this.videoToPlay)
    }
    else {
      this.videoToPlay = { video, options };
      this.videoToPlay$.next({ video , options })
    }
  }

  public stopVideo() {
    this.videoToPlay = null;
    this.videoToPlay$.next(null);
  }

  public goToEpisodesList() {
    if(this.videoToPlay) {
      this.router.navigate([`season-detail`], {state: {id: this.videoToPlay?.video.videoType.id}});
      this.stopVideo();
    }
  }

  // public goToEpisodesList() { // Removed due to ticket: https://nomadoptimist.atlassian.net/browse/MBL-220
  //   if (this.playlistOptions) {
  //     this.stopVideo();
  //     if (this.playlistOptions.context === ContextEnum.default) {
  //       //season
  //       this.router.navigate([`season-detail`], {state: {id: this.playlistOptions.seasonId}});
  //     }
  //     if (this.playlistOptions.context === ContextEnum.playlist) {
  //       //playlist
  //       this.router.navigateByUrl(`/playlist/${this.playlistOptions.playlistType}`);
  //     }
  //     if (this.playlistOptions.context === ContextEnum.categories) {
  //       this.router.navigate([`list/category`], {state: {name: this.playlistOptions.category}});
  //     }
  //     if (this.playlistOptions.context === ContextEnum.trainings) {
  //       this.router.navigate([`list/training`], {state: {name: this.playlistOptions.training}});
  //     }
  //     if (this.playlistOptions.context === ContextEnum.news) {
  //       //news-suggested-trendings
  //       this.router.navigateByUrl(`/layout/home#news`);
  //     }
  //   }
  // }

  public nextVideo() {
    // if (this.playlist && this.videoToPlay) {
    //   const index = this.playlist.findIndex(videoPlaylist => videoPlaylist.video.id === this.videoToPlay?.video.id);
    //   if (index > -1 && index < this.playlist.length - 1) {
    //     if (index === this.playlist.length - 2) {
    //       this.playVideo(this.playlist[index + 1].video, {
    //         context: this.videoToPlay.options.context,
    //         hasNext: false,
    //       });
    //     } else {
    //       this.playVideo(this.playlist[index + 1].video, {
    //         context: this.videoToPlay.options.context,
    //         hasNext: true,
    //       });
    //     }
    //   }
    // }
    if(this.typeOfCoursePlaylist$.value.length > 0 && this.videoToPlay) {
      this.playVideo(this.typeOfCoursePlaylist$.value[0].video, {
        context: this.videoToPlay.options.context,
        fetchTypeOfCourse: false
      })
      this.typeOfCoursePlaylist$.next(this.typeOfCoursePlaylist$.value.slice(1))
    }
  }

  private checkIfNextVideo(videoId: string) {
    if (this.playlist) {
      const index = this.playlist.findIndex(videoPlaylist => videoPlaylist.video.id === videoId);
      if (index > -1 && index < this.playlist.length - 1) {
        return index !== this.playlist.length - 1;
      }
    }
    return false;
  }

  getSameTypeOfCourseVideos() {
    if(!this.videoToPlay?.video.typeOfCourse?.typeOfCourse) return
    // Fetch the list of videos of the same type of the video currently playing
    // Exclude the one currently playing
    // Sort the one in current playlist first
    this.trainingService.getTrainingByType(this.videoToPlay?.video.typeOfCourse?.typeOfCourse).subscribe((res) => {
      this.typeOfCoursePlaylist$.next(
        res.videoPlaylistItems
        .filter((video) => video.video.id !== this.videoToPlay?.video.id)
        .sort((a, b) => {
          if(this.playlist?.find(v => v.video.id === a.video.id)) return -1
          return 1
        })
      )
    })
  }

  updateWatchedTime(time: number) {
    if(!this.videoToPlay) return;
    const idPlaylist = this.videoToPlay?.options.playlistId
    this.http.put(`${environment.apiUrl}/api/video/watchTime`, {
      id: this.videoToPlay?.video.id,
      watchTime: time,
      ...(idPlaylist ? { idPlaylist: +idPlaylist } : {})
    }).subscribe();
    this.watchTimeUpdated.emit({ video: this.videoToPlay?.video, watchTime: time });
  }
}
