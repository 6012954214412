<ion-item class="form-input" [formGroup]="form" lines="full" [ngClass]="{'invalid' : withError && !disableErrors &&  form.controls[formName].touched}">
  <ion-icon *ngIf="iconStart"
            slot="start"
            [name]="iconStart"
            [color]="withError && !disableErrors && form.controls[formName].touched ? 'error' : 'dark'"></ion-icon>
  <ng-container *ngIf="formName.length; else inputNoForm">
    <ion-input  *ngIf="!autocompleteType" 
                [placeholder]="placeholder"
                (ionFocus)="handleFocus($event)" (ionBlur)="handleBlur($event)" #formInput (keypress)="keypress($event)"
                [type]="type" [formControlName]="formName" [ngClass]="{'invalid' : withError && !disableErrors && form.controls[formName].touched}"></ion-input>
    <ion-input  *ngIf="autocompleteType" 
                appAutofill
                [placeholder]="placeholder"
                [autocomplete]="autocompleteType"
                (ionFocus)="handleFocus($event)" (ionBlur)="handleBlur($event)" #formInput (keypress)="keypress($event)"
                [type]="type" [formControlName]="formName" [ngClass]="{'invalid' : withError && !disableErrors && form.controls[formName].touched}"></ion-input>
  </ng-container>
  <ng-template #inputNoForm>
    <ion-input [placeholder]="placeholder"
               [type]="type" (ionFocus)="handleFocus($event)" (ionBlur)="handleBlur($event)"></ion-input>
  </ng-template >

  <ion-icon *ngIf="iconEnd"
            slot="end"
            [name]="iconEnd"
            [color]="withError && !disableErrors && form.controls[formName].touched ? 'error' : 'dark'" (click)="bangIconEnd.emit()"></ion-icon>
</ion-item>
<div class="form-input-errors" *ngIf="withError && form && form.controls[formName].invalid && form.controls[formName].touched">
    <ion-icon *ngIf="!disableErrors" color="warning" name="icon-warning"></ion-icon>
    <p *ngIf="!disableErrors && form.controls[formName].value.length">{{ 'auth.modals.inputs.' + formName + '.invalid' | translate }}</p>
    <p *ngIf="!disableErrors && !form.controls[formName].value.length">{{ 'auth.modals.inputs.' + formName + '.required' | translate }}</p>
  </div>
