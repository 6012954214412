import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, Observable, switchMap, take, tap} from "rxjs";
import {AuthService} from "../services/auth.service";
import {TokenStorageService} from "../services/token.service";
import {throwError} from 'rxjs';
import {filter} from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private tokenStorage: TokenStorageService,
              private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<any> {
    const token = this.tokenStorage.getToken();
    let authReq = req;
    if (token && (!req.url.includes('/auth') || req.url.includes('/auth/logout')) &&
      !req.url.includes('/public/users/verify-email') && !req.url.includes('/wp-json')) {
      authReq = this.setHeaderToRequest(token, req);
    }
    return next.handle(authReq).pipe(catchError((error => this.manageError(error, req, next))));
  }

  setHeaderToRequest(token: string, req: HttpRequest<any>) {
    const cloned = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`)
    });
    return cloned;
  }

  manageError(error: any, req: HttpRequest<any>, next: HttpHandler) {
    if (error.url.includes('/api/auth')
      || error.url.includes('/users/reset_password')
      || error.url.includes('public/users')) {
      return throwError(error);
    }
    if (error.status === 401) {
      const refreshToken = this.tokenStorage.getRefreshToken();
      const token = this.tokenStorage.getToken();
      
      if (token && refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((token: any) => {
            return next.handle(this.setHeaderToRequest(token, req));
          }),
          catchError((error) => {
            this.authService.logout();
            return throwError(error);
          })
        )
      }
      else this.tokenStorage.signOut()
      throw error;
      
      return this.tokenStorage.token.pipe(
        filter(token => token !== null),
        take(1),
        switchMap((token) => next.handle(this.setHeaderToRequest(token, req)))
      );
    }
    throw error;
  }
}


/*
((token: any) => {
  console.log('new token', token);
  const cloned = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`)
  });
  return next.handle(cloned).pipe(
    tap((res) => {
      console.log('request replayed', res);
    })
  );
})*/
