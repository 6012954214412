import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {layout} from './layout.component';

const routes: Routes = [
  {
    path: '',
    component: layout,
    children: [
      {
        path: 'shop',
        loadChildren: () => import('../pages/menu-pages/shop/shop.module').then(m => m.ShopPageModule)
      },
      {
        path: 'program',
        loadChildren: () => import('../pages/menu-pages/program/program.module').then(m => m.ProgramPageModule)
      },
      {
        path: 'home',
        loadChildren: () => import('../pages/menu-pages/home/home.module').then(m => m.HomePageModule)
      },
      {
        path: 'blog',
        loadChildren: () => import('../pages/menu-pages/blog/blog.module').then(m => m.BlogPageModule)
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class LayoutRoutingModule {
}
