import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { ToastComponent } from './toast-component/toast-component.component';
import {TranslateModule} from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [ToastComponent],
  imports: [CommonModule, TranslateModule, IonicModule],
  exports: [ToastComponent]
})
export class ToastModule { }
