import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ToastService} from "../components/toast/toast.service";
import {Platform} from "@ionic/angular";
import {Network} from '@capacitor/network';

export enum ConnectionStatus {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  initialized = false;
  initializeDone = new Subject<ConnectionStatus>()
  
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.Offline);

  constructor(private toastService: ToastService,
              private zone: NgZone,
              private platform: Platform) {
    this.platform.ready().then(() => {
      this.initializeNetworkEvents();
      Network.getStatus().then(s => {
        console.log('Network status', s);
        const status = s.connected ? ConnectionStatus.Online : ConnectionStatus.Offline;
        this.status.next(status);
        this.initialized = true;
        this.initializeDone.next(status)
        this.updateNetworkStatus(status);
      });
    });
  }

  public initializeNetworkEvents() {
    Network.addListener('networkStatusChange', (status) => {
      this.zone.run(() => {
        console.log('Network status changed', status);
        const connectionStatus = status.connected ? ConnectionStatus.Online : ConnectionStatus.Offline;
        this.updateNetworkStatus(connectionStatus);
      });
    });
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    if (this.status.getValue() === ConnectionStatus.Offline && status === ConnectionStatus.Online) {
      this.toastService.showToaster(`offline.toast.online`, 'offline.toast.title', 'success');
    }
    if (status === ConnectionStatus.Offline) {
      this.toastService.showToaster(`offline.toast.offline`, 'offline.toast.title', 'warning');
    }
    this.status.next(status);
    // this.toastService.showToaster(`offline.toast.${status === ConnectionStatus.Offline ? 'offline' : 'online'}`, 'offline.toast.title', 'warning');
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): BehaviorSubject<ConnectionStatus> {
    return this.status;
  }
}
