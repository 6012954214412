import { IVideoPlaylistItem } from "../resources/playlist/video-playlist-item.app.interface";
import { IVideoPlaylist } from "../resources/playlist/video-playlist.interface";
import { PlaylistTypeEnum } from "../resources/video/playlist-type.enum";

export function applyPlaylistVideoItemListEvent(list: IVideoPlaylistItem[], event: { type: 'add' | 'remove', videoId: string, playlistType?: PlaylistTypeEnum }) {
  return list.map(video => applyListEventToVideoPlaylistItem(video, event))
}

export function applyListEventToVideoPlaylistItem(videoPlaylistItem: IVideoPlaylistItem, event: { type: 'add' | 'remove', videoId: string, playlistType?: PlaylistTypeEnum }) {
  if (videoPlaylistItem.video.id !== event.videoId) return videoPlaylistItem
  else {
    let isInFavourites = videoPlaylistItem.video.isInFavourites;
    let isInMyList = videoPlaylistItem.video.isInMyList;
    let isItDownloaded = videoPlaylistItem.video.isItDownloaded;
    if (event.playlistType === PlaylistTypeEnum.VIDEOPLAYLIST_MYFAVOURITES)
      isInFavourites = event.type === 'add'
    if (event.playlistType === PlaylistTypeEnum.VIDEOPLAYLIST_MYLIST)
      isInMyList = event.type === 'add'
    if (event.playlistType !== PlaylistTypeEnum.VIDEOPLAYLIST_MYFAVOURITES && event.playlistType !== PlaylistTypeEnum.VIDEOPLAYLIST_MYLIST)
      isItDownloaded = event.type === 'add'
    return {
      ...videoPlaylistItem,
      video: {
        ...videoPlaylistItem.video,
        isInFavourites,
        isInMyList,
        isItDownloaded
      }
    }
  }
}

export function applyListEventToPlaylist(playlist: IVideoPlaylist, event: { type: 'add' | 'remove', videoId: string, playlistType?: PlaylistTypeEnum }) {
  return {
    ...playlist,
    videoPlaylistItems: playlist.videoPlaylistItems.map(item => {
      if (item.video.id !== event.videoId) return item
      else {
        let isInFavourites = item.video.isInFavourites;
        let isInMyList = item.video.isInMyList;
        let isItDownloaded = item.video.isItDownloaded;
        if (event.playlistType === PlaylistTypeEnum.VIDEOPLAYLIST_MYFAVOURITES)
          isInFavourites = event.type === 'add'
        if (event.playlistType === PlaylistTypeEnum.VIDEOPLAYLIST_MYLIST)
          isInMyList = event.type === 'add'
        if (event.playlistType !== PlaylistTypeEnum.VIDEOPLAYLIST_MYFAVOURITES && event.playlistType !== PlaylistTypeEnum.VIDEOPLAYLIST_MYLIST)
          isItDownloaded = event.type === 'add'
        return {...item, video: {...item.video, isInFavourites, isInMyList, isItDownloaded}}
      }
    })
  }
}
