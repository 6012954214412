<div class="toaster-container">
    <div *ngFor="let toast of currentToasts; index as i">
      <div class="toaster {{toast.type}}" [ngClass]="{'show': toast.isActive, 'hide': !toast.isActive}"
           (click)="removeToast(toast)">
        <!--<ion-icon class="toaster__icon {{toast.type}}" [name]="toast.icon"></ion-icon>-->
          <div class="toaster__content">
            <p *ngIf="toast.title" class="title">{{toast.title | translate }}</p>
            <p class="light" *ngIf="toast.type !== 'unknown-error'">{{toast.message | translate }}</p>
            <span class="http-error" *ngIf="toast.type === 'unknown-error'" (click)="copy(toast.message)">Copier l'erreur</span>
            <span class="http-error" *ngIf="toast.type === 'unknown-error'" (click)="disable()">Désactiver les erreurs</span>
          </div>
        <!--<ion-icon class="toaster__cross" name="icon-close-toat"></ion-icon>-->
      </div>
    </div>
  </div>
