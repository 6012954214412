<section class="new" [ngClass]="{'success-section': successReset}">
  <ng-container *ngIf="!successReset; else success">
    <div class="new__title">
      <h1>{{ 'auth.pages.newPassword.title' | translate }}</h1>
      <p class="subtitle">{{ 'auth.pages.newPassword.subtitle' | translate }}</p>
      <p class="subtitle"><br>{{ 'auth.pages.newPassword.secondSubtitle' | translate }}</p>
    </div>
    <div class="new__form">
      <mbl-pwd-confirm (formSubmitEvent)="formSubmit($event)"></mbl-pwd-confirm>
    </div>
  </ng-container>
  <ng-template #success>
    <div class="new__title">
      <h1>{{ 'auth.pages.newPassword.successTitle' | translate }}</h1>
      <!--<p class="subtitle">{{ 'auth.pages.newPassword.successSubtitle' | translate }}</p>-->
      <p class="subtitle">Rendez-vous sur l’app pour vous connecter avec votre nouveau mot de passe ! <br> Vous pouvez fermer cette fenêtre</p>
    </div>
    <div class="new__form">
      <div style="display: flex;align-items: center;justify-content: space-around;">
        <a href="https://apps.apple.com/fr/app/my-best-life/id1545726005"><img src="/assets/img/stores/appstore-black.svg" alt="Apple store logo"></a>
        <a href="https://play.google.com/store/apps/details?id=com.app.mybestlife"><img src="/assets/img/stores/googleplay-black.svg" alt="Google store logo"></a>
      </div>
      <!--<mbl-button color="black" size="full" iconEnd="icon-arrow-right" [iconEndSmall]="true" (bang)="redirectLogin()">
        {{ 'auth.connect' | translate }}
      </mbl-button>-->
    </div>
  </ng-template>
  <mbl-bottom-login-register *ngIf="!successReset" [isLogin]="true" (clickToRedirect)="redirectLogin()"></mbl-bottom-login-register>
</section>
