import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { map, mergeMap, Observable, Subject, tap } from "rxjs";
import { environment } from "../../../../environments/environment";
import { OfflineManagerService } from "../../services/offline-manager.service";
import { applyPlaylistVideoItemListEvent } from '../../utils/video.util';
import { CategoryEnum } from '../category/category.enum';
import { videoPlaylistAPItoVideoPlaylist, videoPlaylistItemAPItoVideoPlaylistItem } from "../playlist/playlist.mapper";
import { IVideoPlaylistItemAPI } from '../playlist/video-playlist-item.api.interface';
import { IVideoPlaylistItem } from "../playlist/video-playlist-item.app.interface";
import { IVideoPlaylistAPI } from "../playlist/video-playlist.api.interface";
import { IVideoPlaylist } from "../playlist/video-playlist.interface";
import { UserService } from "../user/user.service";
import { TrainingType } from './course-type.enum';
import { PlaylistTypeEnum } from "./playlist-type.enum";
import { IVideoOffline } from "./video-offline.interface";
import { IVideo } from "./video.app.interface";
import { videoAPItoVideo } from "./video.mapper";

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  events$ = new Subject<{ type: 'add' | 'remove', videoId: string, playlistType: PlaylistTypeEnum }>();

  constructor(private http: HttpClient,
              private offlineManagerService: OfflineManagerService,
              private userService: UserService) {

    this.events$.subscribe((event) => {
      if(this.userService.playlistInProgress$.value) {
        this.userService.playlistInProgress$.next({
          ...this.userService.playlistInProgress$.value,
          videoPlaylistItems: applyPlaylistVideoItemListEvent(this.userService.playlistInProgress$.value.videoPlaylistItems, event)
        })
      }

      if(this.userService.playlistMyList$.value) {
        this.userService.playlistMyList$.next({
          ...this.userService.playlistMyList$.value,
          videoPlaylistItems: applyPlaylistVideoItemListEvent(this.userService.playlistMyList$.value.videoPlaylistItems, event)
        })
      }

      if(this.userService.playlistFavourites$.value) {
        this.userService.playlistFavourites$.next({
          ...this.userService.playlistFavourites$.value,
          videoPlaylistItems: applyPlaylistVideoItemListEvent(this.userService.playlistFavourites$.value.videoPlaylistItems, event)
        })
      }
    })
  }

  getVideoById(id: string): Observable<IVideo> {
    return this.http.get<IVideoPlaylistItemAPI>(`${environment.apiUrl}/api/videos/${id}`).pipe(
      map((videoPlaylistItemAPI: IVideoPlaylistItemAPI) => {
        return videoAPItoVideo(videoPlaylistItemAPI.video);
      })
    )
  }

  getVideoPlaylistItemById(id: string): Observable<IVideoPlaylistItem> {
    return this.http.get<IVideoPlaylistItemAPI>(`${environment.apiUrl}/api/videos/${id}`).pipe(
      map((videoPlaylistItemAPI: IVideoPlaylistItemAPI) => {
        return videoPlaylistItemAPItoVideoPlaylistItem(videoPlaylistItemAPI);
      }),
      mergeMap((videoPlaylistItem: IVideoPlaylistItem) => {
        console.log(videoPlaylistItem);
        const playlist = {
          videoPlaylistItems: [videoPlaylistItem]
        };
        return this.checkIfVideosIsDownloaded(playlist);
      }),
      map((playlist: any) => playlist.videoPlaylistItems[0])
    )
  }

  getVttFile(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/videos/getvtt/${id}`, {responseType: 'text'});
  }

  search(value: string) {
    return this.http.get<IVideoPlaylistAPI>(`${environment.apiUrl}/api/videosearch/${value}`).pipe(
      map((playlistAPI) => videoPlaylistAPItoVideoPlaylist(playlistAPI).videoPlaylistItems)
    )
  }

  getVideos(): Observable<IVideoPlaylist> {
    return this.http.get<IVideoPlaylistAPI>(`${environment.apiUrl}/api/videos`).pipe(
      map((playlistAPI: IVideoPlaylistAPI) => {
        return videoPlaylistAPItoVideoPlaylist(playlistAPI);
      })
    );
  }

  get2Videos() {
    return this.http.get<IVideoPlaylistAPI>(`${environment.apiUrl}/api/videos?itemsPerPage=2`).pipe(
      tap((playlistAPI: IVideoPlaylistAPI) => {
        console.log(playlistAPI)
      }),
      map((playlistAPI: IVideoPlaylistAPI) => {
        return videoPlaylistAPItoVideoPlaylist(playlistAPI);
      })
    );
  }

  getPlaylist(type: PlaylistTypeEnum) {
    return this.http.get<any>(`${environment.apiUrl}/api/video_playlists/type/${type.toString()}`).pipe(
      map((videoPlaylist: IVideoPlaylistAPI) => {
        return videoPlaylistAPItoVideoPlaylist(videoPlaylist);
      }),
      mergeMap((playlist: IVideoPlaylist) => {
        return this.checkIfVideosIsDownloaded(playlist);
      }),
      tap((playlist) => this.userService.updatePlaylist(type, playlist)),
    );
  }

  removeVideoFromPlaylist(videoId: string, type: PlaylistTypeEnum) {
    return this.http.delete(`${environment.apiUrl}/api/video_playlists/${type}/video/${videoId}`).pipe(
      tap(() => {
        this.events$.next({type: 'remove', videoId, playlistType: type});
        this.getPlaylist(type).subscribe();
      })
    );
  }

  addVideoToPlaylist(videoId: string, type: PlaylistTypeEnum) {
    return this.http.post(`${environment.apiUrl}/api/video_playlists/${type}/video/${videoId}`, {}).pipe(
      tap(() => {
        this.events$.next({type: 'add', videoId, playlistType: type});
        this.getPlaylist(type).subscribe();
      })
    );
  }

  async getDownloadedPlaylist() {
    return await this.offlineManagerService.getDownloadedVideos().then((videos: IVideoOffline[]) => {
      console.log(videos);
      this.userService.updateDownloadedPlaylist(videos);
    });
  }

  async removeVideoFromDownloadedPlaylist(videoId: string) {
    return await this.offlineManagerService.removeDownloadedVideo(videoId).then(() => {
      this.getDownloadedPlaylist();
      this.events$.next({
        type: 'remove',
        videoId: videoId,
        playlistType: PlaylistTypeEnum.VIDEOPLAYLIST_DOWNLOADED
      });
      this.updatePlaylistVideosWithDownloaded();
      return videoId;
    });
  }

  async removeVideoListFromDownloadedPlaylist(videoIds: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.offlineManagerService.removeDownloadedVideoList(videoIds).then(() => {
        videoIds.map((id) => {
          this.events$.next({
            type: 'remove',
            videoId: id,
            playlistType: PlaylistTypeEnum.VIDEOPLAYLIST_DOWNLOADED
          });
        });
        this.getDownloadedPlaylist();
        this.updatePlaylistVideosWithDownloaded();
        resolve(true);
      });
    });
  }

  async addVideoToDownloadedPlaylist(video: IVideoPlaylistItem) {
    return await this.offlineManagerService.addDownloadedVideo(video).then(() => {
      this.getDownloadedPlaylist();
      this.events$.next({
        type: 'add',
        videoId: video.video.id,
        playlistType: PlaylistTypeEnum.VIDEOPLAYLIST_DOWNLOADED
      });
      this.updatePlaylistVideosWithDownloaded();
      return video;
    }, () => {
      console.log('error');
      this.getDownloadedPlaylist();
      this.events$.next({
        type: 'add',
        videoId: video.video.id,
        playlistType: PlaylistTypeEnum.VIDEOPLAYLIST_DOWNLOADED
      });
      this.updatePlaylistVideosWithDownloaded();
      return video;
    });
  }

  checkIfVideosIsDownloaded(playlist: any) {
    const playlistToCheck = playlist;
    return this.offlineManagerService.getDownloadedVideoIds().pipe(
      map((ids: string[]) => {
        if (ids && ids.length > 0) {
          playlistToCheck.videoPlaylistItems.forEach((video: IVideoPlaylistItem) => {
            if (ids.includes(video.video.id)) {
              if (!video.video.isItDownloaded) {
                this.events$.next({
                  type: 'add',
                  videoId: video.video.id,
                  playlistType: PlaylistTypeEnum.VIDEOPLAYLIST_DOWNLOADED
                });
              }
              video.video.isItDownloaded = true;
            } else {
              if (video.video.isItDownloaded) {
                this.events$.next({
                  type: 'remove',
                  videoId: video.video.id,
                  playlistType: PlaylistTypeEnum.VIDEOPLAYLIST_DOWNLOADED
                });
              }
              video.video.isItDownloaded = false;
            }
          });
        }
        return playlistToCheck;
      })
    );
  }

  updatePlaylistVideosWithDownloaded() {
    this.checkIfVideosIsDownloaded(this.userService.playlistInProgress$.value).subscribe((playlist) => {
      this.userService.playlistInProgress$.next(playlist);
    });
    this.checkIfVideosIsDownloaded(this.userService.playlistFavourites$.value).subscribe((playlist) => {
      this.userService.playlistFavourites$.next(playlist);
    });
    this.checkIfVideosIsDownloaded(this.userService.playlistMyList$.value).subscribe((playlist) => {
      this.userService.playlistMyList$.next(playlist);
    });
  }

  getVideosBySeasonId(id: string): Observable<IVideoPlaylist> {
    return this.http.get<IVideoPlaylistAPI>(`${environment.apiUrl}/api/videos?videoType.id=${id}&order[typeOfCourse.typeOfCourse]=ASC&order[videoType.nbRank]=ASC`).pipe(
      map((playlist: IVideoPlaylistAPI) => {
        return videoPlaylistAPItoVideoPlaylist(playlist);
      })
    );
  }

  getVideosFilter({
                    category,
                    training
                  }: { category?: CategoryEnum, training?: TrainingType }): Observable<IVideoPlaylist> {
    let url = `${environment.apiUrl}/api/videos?order[typeOfCourse.typeOfCourse]=ASC&order[videoType.nbRank]=ASC&`;
    if (category) {
      url += `categories.category=${category}&`;
    }
    if (training) {
      url += `typeOfCourse=${training}&`;
    }
    return this.http.get<IVideoPlaylistAPI>(url).pipe(
      map((playlist: IVideoPlaylistAPI) => {
        return videoPlaylistAPItoVideoPlaylist(playlist);
      })
    );
  }
}
