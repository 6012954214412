import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormInputComponent} from "./form-input.component";
import {IonicModule} from "@ionic/angular";
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutofillModule } from 'src/app/auth/shared/directives/autofill.module';


@NgModule({
  declarations: [FormInputComponent],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
    AutofillModule
  ],
  exports: [FormInputComponent]
})
export class FormInputModule {
}
