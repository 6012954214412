<div size="12" class="ion-text-center bottom ion-wrap ion-no-padding">
    <p (click)="clickRedirect()">
      <ng-container *ngIf="isLogin">
        <span class="light">{{ 'auth.modals.loginRegister.haveAccount' | translate }}</span> {{ 'auth.modals.loginRegister.bottom-login' | translate }}
      </ng-container>
      <ng-container *ngIf="!isLogin">
        <span class="light">{{ 'auth.modals.loginRegister.notAccount' | translate }}</span> {{ 'auth.modals.loginRegister.bottom-register' | translate }}
      </ng-container>
    </p>
    <p (click)="redirectContact()">
      <span class="light">{{ 'auth.modals.loginRegister.deleteAccount' | translate }}</span> {{ 'auth.modals.loginRegister.bottom-delete-account' | translate }}
    </p>
</div>
