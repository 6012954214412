import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MblModalComponent } from "../../../shared/components/mbl-modal/mbl-modal.component";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Browser } from '@capacitor/browser';
import { CommonModule } from '@angular/common';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  standalone: true,
  selector: 'app-web-view',
  imports: [MblModalComponent, CommonModule],
  templateUrl: './web-view.component.html',
  styleUrls: ['./web-view.component.scss'],
})
export class WebViewComponent implements OnInit, AfterViewInit, OnDestroy {

  url!: string
  sanitizeUrl: any;

  accessNeededFn = this.accessNeeded.bind(this);
  accessAcceptedFn = this.accessAccepted.bind(this);

  constructor(private sanitizer: DomSanitizer) {
  }


  @ViewChild('iframe') iframe!: ElementRef;

  ngOnInit() {
    this.sanitizeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  ngAfterViewInit(): void {
    window.addEventListener('message', this.accessNeededFn);
    window.addEventListener('message', this.accessAcceptedFn);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.accessNeededFn);
    window.removeEventListener('message', this.accessAcceptedFn);
  }

  getSanitizeUrl() {
    this.sanitizeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  accessAccepted(event: any) {
    console.log(event)
    if (event.data === 'access-accepted') {
      console.log('Reset iframe')
      this.sanitizeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
  }

  accessNeeded(event: any) {
    console.log(event)
    if (event.data === 'access-needed') {
      console.log('Opening browser for access request')
      Browser.open({ url: environment.shopUrl + '/contact' })

      Browser.addListener('browserFinished', () => {
        this.iframe.nativeElement.src += ''
      })
    }
  }

  changeUrl() {
    Keyboard.hide()
  }

}
