import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfilePictureComponent} from "./profile-picture.component";
import { LoaderComponent } from '../loader/loader.component';


@NgModule({
  declarations: [ProfilePictureComponent],
  imports: [
    CommonModule,
    LoaderComponent
  ],
  exports: [ProfilePictureComponent]
})
export class ProfilePictureModule {
}
