import {Component} from '@angular/core';
import {ToastService} from '../toast.service';
import {Clipboard} from '@capacitor/clipboard';

@Component({
  selector: 'mbl-toast',
  styleUrls: ['./toast-component.component.scss'],
  templateUrl: './toast-component.component.html',
})
export class ToastComponent {

  public currentToasts: Array<{ title: string, message: string, isActive: boolean, id: string, type: string, icon?: string }> = [];

  showUnknownErrors = true;

  constructor(private readonly toastService: ToastService) {
    this.subscribeToToasts();
  }

  public subscribeToToasts(): void {
    this.toastService.toastEvents.subscribe((toasts) => {
      console.log('toasts', toasts);
      if (toasts.type === 'unknown-error' && !this.showUnknownErrors) return;
      const id = Math.random().toString(36).substr(2, 9);
      const currentToast = {
        title: toasts.title,
        id,
        message: toasts.message,
        isActive: true,
        type: toasts.type.length ? toasts.type : 'info',
        icon: this.getIcon(id, toasts.type.length ? toasts.type : 'info')
      };
      if (this.currentToasts.length > 2) {
        this.currentToasts.shift();
      }
      this.currentToasts.unshift(currentToast);
      setTimeout(() => {
        this.removeToast(currentToast);
      }, !!toasts.time ? toasts.time : 2000);
    });
  }

  public removeToast(toast: { title: string, message: string, isActive: boolean, id: string }): void {
    toast.isActive = false;
    setTimeout(() => {
      this.currentToasts = this.currentToasts.filter(t => t.id !== toast.id);
    }, 300);
  }

  public getIcon(id: string, type: string): string {
    switch (type) {
      case 'success':
        return 'icon-validation';
      case 'error':
        return 'icon-error';
      case 'unknown-error':
        return 'icon-error';
      case 'warning':
        return 'icon-warning';
      case 'info':
        return 'icon-info';
      default:
        return '';
    }
  }

  copy(message: string) {
    Clipboard.write({string: message})
    this.toastService.showToaster('Erreur copiée', 'Succès', 'success');
  }

  disable() {
    this.showUnknownErrors = false
  }
}
