import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from "./button.component";
import {IonicModule} from "@ionic/angular";
import {LoaderComponent} from "../loader/loader.component";


@NgModule({
  declarations: [ButtonComponent],
  imports: [
    CommonModule,
    IonicModule,
    LoaderComponent
  ],
  exports: [ButtonComponent]
})
export class ButtonModule {
}
