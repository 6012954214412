import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ToastService } from '../../shared/components/toast/toast.service';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    private readonly injector: Injector,
    private toastService: ToastService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.url?.includes(environment.apiUrl + '/api')) {
            const error = this.getError(err);
            const message = this.getText(error);
            if (message)
              this.toastService.showToaster(
                message,
                'toaster-errors.title',
                'error'
              );
            else console.debug(error);
            // else this.toastService.showToaster(JSON.stringify(err), 'toaster-errors.title-unknown', 'unknown-error', 5000);
          }
        }
        throw err;
      })
    );
  }

  getError(err: HttpErrorResponse) {
    return err.error['message'] || null;
  }

  getText(error: string) {
    let message = this.getTranslation('errors.error.' + error);
    if (!message) message = this.getTranslation('errors.security.' + error);
    if (!message) message = this.getDynamicTranslation(error);
    return message || false;
  }

  getTranslation(err: string): string | false {
    try {
      const translateService = this.injector.get(TranslateService);
      let translate = err;
      translate = translateService.instant(err);
      if (translate !== err) return translate;
      return false;
    } catch {
      return false;
    }
  }

  getDynamicTranslation(err: string): string | false {
    return false;
  }
}
