import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import { BottomLoginRegisterComponent } from './bottom-login-register.component';


@NgModule({
  declarations: [BottomLoginRegisterComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  exports: [BottomLoginRegisterComponent]
})
export class BottomLoginRegisterModule {
}
