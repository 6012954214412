import {Component, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {Keyboard} from '@capacitor/keyboard';
import {StatusBar} from '@capacitor/status-bar';
import {Platform} from '@ionic/angular';
import {IndexedDbService} from './shared/resources/indexeddb/indexeddb.service';
import {MagicPlayerService} from "./shared/components/magic-player/shared/magic-player.service";
import {register} from "swiper/element/bundle";
import {OrientationType, ScreenOrientation} from "@capawesome/capacitor-screen-orientation";
import {Capacitor} from "@capacitor/core";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { StartService } from './pages/start/shared/start.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  videoToPlay$ = this.magicPlayerService.videoToPlay$;

  constructor(private readonly platform: Platform,
              private readonly indexedDbService: IndexedDbService,
              private startService: StartService,
              private readonly router: Router,
              private magicPlayerService: MagicPlayerService,
              private zone: NgZone) {
    this.indexedDbService.initializeDbLocal();
    this.initializeApp();
    if (this.platform.is('capacitor')) {
      if (Capacitor.isPluginAvailable('StatusBar')) {
        StatusBar.setOverlaysWebView({overlay: true});
      }
      if (Capacitor.isPluginAvailable('Keyboard')) {
        Keyboard.setAccessoryBarVisible({isVisible: false});
      }
    }
    this.lockScreen();
  }

  async initializeApp() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register().then((token: any) => {
      console.log('token', token);
    });

    /*PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
        console.log(result)
      } else {
        console.log(result)
        // Show some error
      }
    });*/
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        console.log('App opened with URL: ' + event.url)
        const domain = 'my-bestlife.com';
        const pathArray = event.url.split(domain);
        const appPath = pathArray.pop();
        if (appPath) {
          SplashScreen.hide({ fadeOutDuration: 250 })
          this.router.navigateByUrl(appPath);
        }
      });
    });
  }

  lockScreen = async () => {
    if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
      try {
        await ScreenOrientation.lock({type: OrientationType.PORTRAIT});
      } catch (e) {
        console.debug('Lock screen not supported', e)
      }
    }
  };

}
