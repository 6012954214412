import {Injectable} from '@angular/core';
import {map, mergeMap, Observable, of, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {apiListToApp} from "../../api/api-list.mapper";
import {IApiList} from "../../api/api-list.interface";
import {IVideoAPI} from "../video/video.api.interface";
import {videoAPItoVideo} from "../video/video.mapper";
import {ITypeOfCourse} from "./training.interface";
import {IVideo} from "../video/video.app.interface";
import {TrainingType} from "../video/course-type.enum";
import {IVideoPlaylistAPI} from "../playlist/video-playlist.api.interface";
import {videoPlaylistAPItoVideoPlaylist} from "../playlist/playlist.mapper";
import {IVideoPlaylist} from "../playlist/video-playlist.interface";

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  constructor(private http: HttpClient) {
  }

  getTrainings(): Observable<ITypeOfCourse[]> {
    return this.http.get<IApiList<ITypeOfCourse>>(`${environment.apiUrl}/api/content/homepage/trainings`).pipe(
      map((trainings) => apiListToApp<ITypeOfCourse>(trainings))
    )
  }

  getTrainingById(id: string): Observable<ITypeOfCourse> {
    /*return this.http.get<ITypeOfCourse>(`${environment.apiUrl}/api/videos?typeOfCourse=${typeOfCourse}`).pipe(
      /!*mergeMap((season: ISeason) => {
        return this.http.get<IVideoAPI>(`${environment.apiUrl}/api/videos/${season.firstVideo.id}`).pipe(
          map((video) => ({
            ...season,
            firstVideo: videoAPItoVideo(video)
          })),
        )
      })*!/
    );*/
    return of<any>({
      "@type": "TypeOfCourse",
      "@id": "/api/.well-known/genid/c31a3463ca2690c30e1d",
      "id": "018871ef-cdcd-7c7b-8092-1e20e27d8b5f",
      "typeOfCourse": "TYPEOFCOURSE_6_PACK_SUSPENSION",
      "thumbnailName": "typeofcoursec711a21552c9b6d7bfdcdc6587d3eef2647747a137ad4-647747a137bb6237698296.png",
      "countVideos": 19,
      "thumbnailUrl": "/upload/images/video/typeOfCourse/typeofcoursec711a21552c9b6d7bfdcdc6587d3eef2647747a137ad4-647747a137bb6237698296.png"
    });
  }

  getTrainingByType(typeOfCourse: TrainingType | string): Observable<IVideoPlaylist> {
    return this.http.get<IVideoPlaylistAPI>(`${environment.apiUrl}/api/videos?typeOfCourse=${typeOfCourse}&order[typeOfCourse.typeOfCourse]=ASC&order[videoType.nbRank]=ASC`).pipe(
      map((playlist: IVideoPlaylistAPI) => {
        return videoPlaylistAPItoVideoPlaylist(playlist);
      })
    );
  }

  getFirstTrainingsByType(typeOfCourse: TrainingType | string): Observable<IVideoPlaylist> {
    return this.http.get<IVideoPlaylistAPI>(`${environment.apiUrl}/api/videos?typeOfCourse=${typeOfCourse}&pagination=true&itemsPerPage=5`).pipe(
      map((playlist: IVideoPlaylistAPI) => {
        return videoPlaylistAPItoVideoPlaylist(playlist);
      })
    );
  }

}
