import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./core/guards/auth.guard";
import {OfflineGuard} from "./core/guards/offline.guard";

const routes: Routes = [
  {
    path: 'start',
    canActivate: [OfflineGuard],
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'layout',
    canActivate: [AuthGuard, OfflineGuard],
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
  },
  {
    path: 'gonow',
    canActivate: [AuthGuard, OfflineGuard],
    loadChildren: () => import('./pages/go-now/go-now.module').then(m => m.GoNowPageModule)
  },
  {
    path: 'search',
    canActivate: [AuthGuard, OfflineGuard],
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'program',
    canActivate: [AuthGuard, OfflineGuard],
    loadChildren: () => import('./pages/menu-pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard, OfflineGuard],
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'auth/reinitialiser-mdp',
    canActivate: [OfflineGuard],
    loadChildren: () => import('./auth/pages/new-password/new-password.module').then(m => m.NewPasswordPageModule)
  },
  {
    path: 'auth/reset-password',
    canActivate: [OfflineGuard],
    loadChildren: () => import('./auth/pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'confirmation-email',
    canActivate: [OfflineGuard],
    loadChildren: () => import('./auth/pages/confirmation-email/confirmation-email.module').then(m => m.ConfirmationEmailPageModule)
  },
  {
    path: 'auth/inscription',
    canActivate: [OfflineGuard],
    loadChildren: () => import('./auth/pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'playlist/:type',
    canActivate: [AuthGuard, OfflineGuard],
    loadChildren: () => import('./pages/playlist/playlist.module').then(m => m.PlaylistPageModule)
  },
  {
    path: 'offline',
    loadChildren: () => import('./pages/offline-videos/offline-videos.module').then(m => m.OfflineVideosPageModule)
  },
  {
    path: 'season-detail',
    canActivate: [AuthGuard, OfflineGuard],
    loadChildren: () => import('./pages/season-detail/season-detail.module').then(m => m.SeasonDetailPageModule)
  },
  {
    path: 'video-detail',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/video-detail/video-detail.module').then(m => m.VideoDetailModule)
  },
  {
    path: 'list',
    canActivate: [AuthGuard, OfflineGuard],
    loadChildren: () => import('./pages/category-training-detail/category-training-detail.module').then(m => m.CategoryTrainingDetailPageModule)
  },
  {
    path: 'subscription',
    canActivate: [AuthGuard, OfflineGuard],
    loadChildren: () => import('./pages/subscription-mbl/subscription-mbl.module').then(m => m.SubscriptionMblPageModule)
  },
  {
    path: '',
    redirectTo: '/start?step=ANIMATION_LOGO',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/layout/home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
