import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, mergeMap, Observable } from 'rxjs';
import { ConnectionStatus, NetworkService } from "../../shared/services/network.service";

@Injectable({
  providedIn: 'root'
})
export class OfflineGuard implements CanActivate {

  constructor(private networkService: NetworkService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    return (this.networkService.initialized ?
      this.networkService.getCurrentNetworkStatus() : this.networkService.initializeDone)
      .pipe(
        map((status: ConnectionStatus) => {
          if (status !== ConnectionStatus.Online) {
            this.router.navigate(['/offline']);
            return false;
          } else {
            return true;
          }
        }),
      );
  }

}
