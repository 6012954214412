import {Injectable} from '@angular/core';
import {stepStartPage} from "./start.enum";
import {firstLaunchLandScapeVideo, firstLaunchPortraitVideo, loginLandScapeVideo, loginPortraitVideo} from "./videos";
import {IndexedDbService} from "../../../shared/resources/indexeddb/indexeddb.service";
import {Platform} from "@ionic/angular";
import {Router} from "@angular/router";
import {TokenStorageService} from "../../../auth/shared/services/token.service";

@Injectable({
  providedIn: 'root'
})
export class StartService {

  private step?: stepStartPage;
  private videoPresentation = firstLaunchPortraitVideo;
  private skipVideo: boolean = false;

  constructor(private readonly indexedDbService: IndexedDbService,
              private router: Router,
              private tokenService: TokenStorageService,
              private readonly platform: Platform) {
  }

  //Check if the device size fits the portrait video
  isLandscape() {
    const ratio = this.platform.width() / this.platform.height();
    return ratio > 0.56;
  }

  public async checkIfIsFirstTimeToStart() {
    const alreadyViewFirstVideo = await this.indexedDbService.get('alreadyViewFirstVideo');
    if (alreadyViewFirstVideo) {
      if (this.isLandscape()) {
        this.videoPresentation = loginLandScapeVideo;
      } else {
        this.videoPresentation = loginPortraitVideo;
      }
    } else {
      this.skipVideo = true;
      if (this.isLandscape()) {
        this.videoPresentation = firstLaunchLandScapeVideo;
      } else {
        this.videoPresentation = firstLaunchPortraitVideo;
      }
      await this.indexedDbService.set('alreadyViewFirstVideo', true);
    }
    return this.videoPresentation;
  }

  public setStep(step: stepStartPage) {
    // If not on StartPage, return (to avoid being redirected to /start/progress when not on /start)
    if(!this.router.url.startsWith('/start')) return;
    this.step = step.toLowerCase() as stepStartPage;
    if (step.toLowerCase() === stepStartPage.VIDEO_INTRO) {
      this.router.navigateByUrl('/start/presentation');
    }

    if (step === stepStartPage.PROGRESS) {
      if (!this.tokenService.getToken()) {
        setTimeout(() => this.setStep(stepStartPage.VIDEO_INTRO));
      } else {
        this.router.navigateByUrl('/start/hello');
      }
    }
  }

  getVideoPresentation() {
    return this.videoPresentation;
  }

  getSkipVideo() {
    return this.skipVideo;
  }
}
