import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minTwoDigits'
})
export class MinTwoDigitsPipe implements PipeTransform {
  transform(nb: number | undefined, ...args: unknown[]): string {
    return ((nb || 0) < 10 ? '0' : '') + (nb || 0);
  }
}
