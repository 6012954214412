import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from "./components/button/button.module";
import {CheckboxModule} from "./components/checkbox/checkbox.module";
import {RadioModule} from "./components/radio/radio.module";
import {ToggleModule} from "./components/toggle/toggle.module";
import {SelectModule} from "./components/select/select.module";
import {InputModule} from "./components/input/input.module";
import {FormInputModule} from "./components/form-input/form-input.module";
import {StickyHeaderModule} from "./components/headers/sticky-header/sticky-header.module";
import {TranslateModule} from "@ngx-translate/core";
import {GenericHeaderModule} from './components/headers/generic-header/generic-header.module';
import {TraductionGenderModule} from './pipes/traduction-gender.module';
import {MinTwoDigitsPipe} from "./pipes/minTwoDigits/minTwoDigits.pipe";
import { MarkdownPipe } from './pipes/markdown/markdown.pipe';
import { PluralizationPipe } from './pipes/pluralization/pluralization.pipe';
import { FirstWordBreakPipe } from './pipes/first-word-break/first-word-break.pipe';
import { SanitizeHTMLPipe } from './pipes/sanitizeHTML/sanitize-html.pipe';

@NgModule({
  declarations: [MinTwoDigitsPipe, MarkdownPipe, PluralizationPipe, FirstWordBreakPipe, SanitizeHTMLPipe],
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    RadioModule,
    ToggleModule,
    SelectModule,
    InputModule,
    FormInputModule,
    StickyHeaderModule,
    TranslateModule,
    GenericHeaderModule,
    TraductionGenderModule
  ],
  exports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    RadioModule,
    ToggleModule,
    SelectModule,
    InputModule,
    FormInputModule,
    StickyHeaderModule,
    TranslateModule,
    GenericHeaderModule,
    MinTwoDigitsPipe,
    TraductionGenderModule,
    MarkdownPipe,
    PluralizationPipe,
    FirstWordBreakPipe,
    SanitizeHTMLPipe
  ]
})
export class SharedModule {
}
