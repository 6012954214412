import {Injectable} from '@angular/core';
import {IVideoPlaylistItem} from "../resources/playlist/video-playlist-item.app.interface";
import {IVideoOffline} from "../resources/video/video-offline.interface";
import {IndexedDbService} from "../resources/indexeddb/indexeddb.service";
import {BehaviorSubject, Observable, last, tap} from "rxjs";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {HttpClient, HttpEvent, HttpResponse, HttpProgressEvent} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {DownloadedStatus} from "../resources/video/download-status.enum";

@Injectable({
  providedIn: 'root'
})
export class OfflineManagerService {

  progress = new BehaviorSubject<Record<string, number>>({});

  constructor(private indexedDbService: IndexedDbService,
              private http: HttpClient
  ) {
  }

  async getDownloadedVideos(): Promise<IVideoOffline[]> {
    const videosFromLocalStorage = await this.indexedDbService.get('downloadedVideos');
    if (!videosFromLocalStorage) {
      return Promise.resolve([]);
    } else {
      const videoList = JSON.parse(videosFromLocalStorage);
      const promises = videoList.map((videoId: number) => {
        return Promise.resolve(this.indexedDbService.get(videoId.toString())).then((videoString: string) => JSON.parse(videoString));
      });
      return Promise.all(promises);
    }
  }

  getDownloadedVideoIds(): Observable<any> {
    return fromPromise(this.indexedDbService.get('downloadedVideos') || [])
  }

  async addDownloadedVideo(video: IVideoPlaylistItem): Promise<any> {
    return new Promise(async (resolve, reject) => {
      video.video.downloadedStatus = DownloadedStatus.progress;
      await this.saveVideoToDB(video);

      if (typeof Worker !== 'undefined') {
        const worker = new Worker(new URL('../workers/offline.worker', import.meta.url));
        worker.onmessage = async ({data}) => {
          if (!data) {
            video.video.downloadedStatus = DownloadedStatus.error;
            await this.saveVideoToDB(video);
            reject();
          } else {
            video.video.file = data;
            video.video.downloadedStatus = DownloadedStatus.success;
            await this.saveVideoToDB(video);
            resolve(true);
          }
        };

        this.http.get(`${environment.apiUrl}/api/videos/getMp4/${video.video.id}`, {
          responseType: 'blob', 
          reportProgress: true, 
          observe: 'events'
        }).pipe(
          tap((event: HttpEvent<Blob>) => {
            if(this.progress.value[video.video.id] === undefined) {
              const progressObject = this.progress.value;
              progressObject[video.video.id] = 0;
              this.progress.next(progressObject);
            }
            if(event.type === 3) {
              const progressObject = this.progress.value;
              progressObject[video.video.id] = Math.round((event.loaded / (event.total || 0)) * 100);
              this.progress.next(progressObject);
            }
          }),
          last()
        )
        .subscribe(async (res: HttpEvent<Blob>) => {
            if(res instanceof HttpResponse) {
              const data = await this.blobToBase64(res.body)
              worker.postMessage(data);
            }
          }, async (err) => {
            worker.postMessage(null);
          });

      } else {
        // Web workers are not supported in this environment.
        // You should add a fallback so that your program still executes correctly.
        console.log('Web workers are not supported in this environment.');
        this.http.get(`${environment.apiUrl}/api/videos/getMp4/${video.video.id}`, {
          responseType: 'blob', 
          reportProgress: true, 
          observe: 'events'
        }).pipe(
          tap((event: HttpEvent<Blob>) => {
            if(this.progress.value[video.video.id] === undefined) {
              const progressObject = this.progress.value;
              progressObject[video.video.id] = 0;
              this.progress.next(progressObject);
            }
            if(event.type === 3) {
              const progressObject = this.progress.value;
              progressObject[video.video.id] = Math.round((event.loaded / (event.total || 0)) * 100);
              this.progress.next(progressObject);
            }
          }),
          last()
        )
          .subscribe(async (res: HttpEvent<Blob>) => {
            const progressObject = this.progress.value;
            delete progressObject[video.video.id];
            this.progress.next(progressObject);
            if(res instanceof HttpResponse) {
              video.video.file = await this.blobToBase64(res.body);
              video.video.downloadedStatus = DownloadedStatus.success;
              await this.saveVideoToDB(video);
              resolve(true);
            }
          }, async (err) => {
            const progressObject = this.progress.value;
            delete progressObject[video.video.id];
            this.progress.next(progressObject);
            video.video.downloadedStatus = DownloadedStatus.error;
            await this.saveVideoToDB(video);
            reject();
          });
      }
    });
  }

  private async saveVideoToDB(video: IVideoPlaylistItem) {
    const videosFromDB = await this.indexedDbService.get('downloadedVideos');
    if (!videosFromDB) {
      await this.indexedDbService.set('downloadedVideos', JSON.stringify([video.video.id]));
      await this.indexedDbService.set(video.video.id, JSON.stringify(video));
    } else {
      const videos = JSON.parse(videosFromDB);
      if (!videos.includes(video.video.id)) {
        videos.push(video.video.id);
        await this.indexedDbService.set('downloadedVideos', JSON.stringify(videos));
      }
      await this.indexedDbService.set(video.video.id, JSON.stringify(video));
    }
  }

  blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async removeDownloadedVideo(videoId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const videosFromDB = await this.indexedDbService.get('downloadedVideos');
      if (!videosFromDB) {
        resolve([]);
      } else {
        const videos = JSON.parse(videosFromDB);
        const index = videos.findIndex((videoFromDB: string) => videoFromDB === videoId);
        videos.splice(index, 1);
        return Promise.all([this.indexedDbService.remove(videoId), this.indexedDbService.set('downloadedVideos', JSON.stringify(videos))]).then((resArr) => {
          resolve(true);
        });
      }
    });
  }

  async removeDownloadedVideoList(videoIds: string[]): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const videosFromDB = await this.indexedDbService.get('downloadedVideos');
      if (!videosFromDB) {
        resolve([]);
      } else {
        const videos = JSON.parse(videosFromDB);

        let promiseArray: any[] = [];

        videoIds.map((videoId: string) => {
          const index = videos.findIndex((videoFromDB: string) => videoFromDB === videoId);
          videos.splice(index, 1);
          promiseArray.push(this.indexedDbService.remove(videoId));
        });

        promiseArray.push(this.indexedDbService.set('downloadedVideos', JSON.stringify(videos)));

        return Promise.all(promiseArray).then(() => {
          resolve(true);
        });

        /*return Promise.all([this.indexedDbService.remove(videoId), this.indexedDbService.set('downloadedVideos', JSON.stringify(videos))]).then((resArr) => {
          console.log('res', resArr);
          resolve(true);
        });*/
      }
    });
  }
}
