import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { PwdConfirmComponent } from './pwd-confirm.component';
import { FormInputModule } from "../form-input/form-input.module";
import { ButtonModule } from "../button/button.module";
import {ReactiveFormsModule} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [PwdConfirmComponent],
    exports: [PwdConfirmComponent],
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule,
        FormInputModule,
        ButtonModule,
        ReactiveFormsModule,
    ]
})
export class PwdConfirmModule {
}
