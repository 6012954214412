import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {ModalController} from "@ionic/angular";

@Component({
  standalone: true,
  selector: 'app-mbl-modal',
  imports: [CommonModule, SharedModule],
  templateUrl: './mbl-modal.component.html',
  styleUrls: ['./mbl-modal.component.scss'],
})
export class MblModalComponent implements OnInit {

  constructor(private readonly modalCtrl: ModalController) {
  }

  ngOnInit() {
  }

  close() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

}
