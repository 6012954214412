import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { createPasswordStrengthValidator } from '../../validators/password.validator';

@Component({
  selector: 'mbl-pwd-confirm',
  templateUrl: './pwd-confirm.component.html',
  styleUrls: ['./pwd-confirm.component.scss'],
})
export class PwdConfirmComponent implements OnInit {

  @Output() public formSubmitEvent: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public newForm: FormGroup = new FormGroup({});

  public showFirst = false;
  public showSecond = false;

  public passwordIsFocused = new BehaviorSubject<boolean>(false);
  public confirmPasswordIsFocused = new BehaviorSubject<boolean>(false);



  constructor() { }
  ngOnInit() {
    this.newForm = new FormGroup({
      password: new FormControl('', [
        createPasswordStrengthValidator()]),
      confirmPassword: new FormControl(null, [Validators.required])
    }, validators => this.checkPasswords(validators));
  }


  public checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value
    return pass === confirmPass && pass !== '' ? null : { noSame: true }
  }

  public focusElement($event: Event, type: 'first' | 'second'){
    if(type === 'first'){
      this.passwordIsFocused.next(!this.passwordIsFocused.getValue());
    }else{
      this.confirmPasswordIsFocused.next(!this.confirmPasswordIsFocused.getValue());
    }
  }

  public manageShowPassword(who: 'first' | 'second', value: boolean){
    if(who === 'first'){
      this.showFirst = !value;
    }else{
      this.showSecond = !value;
    }
  }

  public formSubmit(){
    if(this.newForm.valid){
      this.formSubmitEvent.emit(this.newForm);
    }
  }

}
