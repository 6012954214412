import {IVideoAPI} from "./video.api.interface";
import {IVideo} from "./video.app.interface";

export const videoAPItoVideo = (videoAPI: IVideoAPI): IVideo => {
  const rangeRenfo = videoAPI.rangeRenfo || 0;
  let rangeCardio: number | undefined = videoAPI.rangeCardio || 0;
  let rangeMobility: number | undefined = videoAPI.rangeMobility || 0;
  if(rangeMobility === 0 && rangeCardio >= 0) rangeMobility = undefined
  else if(rangeMobility > 0 && rangeCardio === 0) rangeCardio = undefined
  return {
    id: videoAPI.id,
    url: videoAPI.hlsUrl,
    thumbnailUrl: videoAPI.thumbnailsUrl,
    name: videoAPI.name,
    videoName: videoAPI.videoName,
    description: videoAPI.description,
    season: videoAPI.season,
    episode: videoAPI.episode,
    videoType: videoAPI.videoType,
    materials: videoAPI.materials,
    videoDurationInMinutes: videoAPI.videoDurationInMinutes,
    typeOfCourse: videoAPI.typeOfCourse,
    categories: videoAPI.categories || [],
    rangeRenfo: rangeRenfo,
    rangeCardio: rangeCardio,
    rangeMobility: rangeMobility,
    isNew: videoAPI.isNew,
    isInFavourites: videoAPI.isInFavourites,
    isInMyList: videoAPI.isInMyList
  }
}
