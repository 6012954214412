import {IVideoPlaylistItem} from "./video-playlist-item.app.interface";
import {IVideoPlaylistAPI} from "./video-playlist.api.interface";
import {IVideoPlaylist} from "./video-playlist.interface";
import {IVideoPlaylistItemAPI} from "./video-playlist-item.api.interface";
import {videoAPItoVideo} from "../video/video.mapper";
import { timeStringToSeconds } from "src/app/pages/menu-pages/program/shared/utils";

export const videoPlaylistAPItoVideoPlaylist = (playlistAPI: IVideoPlaylistAPI): IVideoPlaylist => {
  return {
    id: playlistAPI.id.toString(),
    totalVideoDurationInMinutes: playlistAPI.totalVideoDurationInMinutes,
    totalWatchTimeInMinutes: playlistAPI.totalWatchTimeInMinutes,
    type: playlistAPI.type,
    filters: playlistAPI.filters,
    videoPlaylistItems: playlistAPI.videoPlaylistItems.map(videoPlaylistItemAPItoVideoPlaylistItem)
    .sort((a, b) => a.position - b.position)
  }
}

export const videoPlaylistItemAPItoVideoPlaylistItem = (playlistItemAPI: IVideoPlaylistItemAPI): IVideoPlaylistItem => {
  return {
    '@id': playlistItemAPI['@id'],
    '@type': playlistItemAPI['@type'],
    id: playlistItemAPI.id,
    position: playlistItemAPI.position,
    video: videoAPItoVideo(playlistItemAPI.video),
    videoPlaylist: playlistItemAPI.videoPlaylist,
    watchTime: playlistItemAPI.watchTime,
    watchTimeInMinutes: playlistItemAPI.watchTimeInMinutes,
    completionPercentage: Math.floor((playlistItemAPI.watchTime / timeStringToSeconds(playlistItemAPI.video.videoDurationInMinutes)) * 100)
  }
}
