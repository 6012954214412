<div class="modal__container">
  <ion-row class="mbl-modal">
    <header>
      <div class="handle"></div>
      <ion-icon name="icon-close" (click)="close()" class="close"></ion-icon>
    </header>
    <section>
      <ng-content></ng-content>
    </section>
  </ion-row>
</div>


