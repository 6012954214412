import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from "../../button/button.module";
import {IonicModule} from "@ionic/angular";
import {GenericHeaderComponent} from './generic-header.component';


@NgModule({
  declarations: [GenericHeaderComponent],
  imports: [
    CommonModule,
    IonicModule,
    ButtonModule,
  ],
  exports: [GenericHeaderComponent]
})
export class GenericHeaderModule {
}
