import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToggleComponent} from "./toggle.component";
import {IonicModule} from "@ionic/angular";


@NgModule({
  declarations: [ToggleComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [ToggleComponent]
})
export class ToggleModule {
}
