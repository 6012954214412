import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { NewPasswordPage } from './new-password.page';
import { BottomLoginRegisterModule } from '../../components/bottom-login-register/bottom-login-register.module';
import {TranslateModule} from '@ngx-translate/core';
import { NewPasswordPageRoutingModule } from './new-password-routing.module';
import { PwdConfirmModule } from 'src/app/shared/components/pwd-confirm/pwd-confirm.module';
import {ButtonModule} from '../../../shared/components/button/button.module';
import { GenericHeaderModule } from "../../../shared/components/headers/generic-header/generic-header.module";


@NgModule({
    declarations: [NewPasswordPage],
    imports: [
        CommonModule,
        NewPasswordPageRoutingModule,
        IonicModule,
        BottomLoginRegisterModule,
        TranslateModule,
        PwdConfirmModule,
        ButtonModule,
        GenericHeaderModule
    ]
})
export class NewPasswordPageModule {}
