import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MagicPlayerComponent} from "./magic-player.component";
import {SharedModule} from "../../shared.module";
import {LoaderComponent} from "../loader/loader.component";


@NgModule({
  declarations: [MagicPlayerComponent],
    imports: [
        CommonModule,
        SharedModule,
        LoaderComponent
    ],
  exports: [MagicPlayerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MagicPlayerModule {
}
