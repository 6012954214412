import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from "@capacitor-community/apple-sign-in";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { BehaviorSubject, Subject, filter, map, mergeMap, tap } from 'rxjs';
import { IApiList } from 'src/app/shared/api/api-list.interface';
import { IUser } from 'src/app/shared/resources/user/user.interface';
import { environment } from '../../../../environments/environment';
import { IndexedDbService } from "../../../shared/resources/indexeddb/indexeddb.service";
import { SubscriptionStatus } from "../../../shared/resources/subscription/subscription-status.enum";
import { NetworkService } from "../../../shared/services/network.service";
import { TokenStorageService } from './token.service';
import { userMapper } from './user.mapper';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user = new BehaviorSubject<any>(null);
  initialized = false;
  initializeDone = new Subject<boolean>()
  initCalled = false;
  rememberMe = true;

  constructor(private readonly httpClient: HttpClient,
              private tokenStorage: TokenStorageService,
              private networkService: NetworkService,
              private indexedDbService: IndexedDbService,
              private router: Router) {
    GoogleAuth.initialize({
      clientId: environment.googleClientId,
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
  }

  private loginWithSN(sn: 'google' | 'facebook' | 'apple', token: string, userInfos?: { email?: string, firstname?: string, lastname?: string }) {
    return this.httpClient.post(`${environment.apiUrl}/api/social/auth`, {[sn]: token}, {
      headers: {
        'X-AUTH-TOKEN': token,
        'X-AUTH-SOCIAL': sn,
        'Content-Type': "application/json"
      }
    }).pipe(
      tap((res: any) => {
        if (!res?.token) {
          this.router.navigate(['/auth/inscription'], {
            queryParams: {
              step: 1,
              user_from_sn: JSON.stringify({
                email: userInfos?.email,
                firstname: userInfos?.firstname,
                lastname: userInfos?.lastname
              })
            }
          });
        }
      })
    );
  }

  public login(email: string, password: string, rememberMe: boolean = false) {
    this.rememberMe = rememberMe;
    return this.httpClient.post(`${environment.apiUrl}/api/auth`, {email, password}, {
      headers: {
        'Authorization': "Basic bm9tYWQtbWJsOmJhY2tJc0Jlc3Qh==", // TODO: what's this token?
        'Content-Type': "application/json"
      }
    }).pipe(map((res: any) => {
      if (res && 'token' in res) {
        if (Capacitor.getPlatform() === 'ios') {
          SavePassword.promptDialog({
              username: email,
              password: password,
          })
          .then(() => console.log('Password saved in keychain'))
          .catch((err) => console.error('Password not saved', err));
        }
        this.tokenStorage.saveToken(res.token, this.rememberMe ? 'localStorage' : 'sessionStorage');
        if (res.refresh_token) {
          this.tokenStorage.saveRefreshToken(res.refresh_token, this.rememberMe ? 'localStorage' : 'sessionStorage');
        }
      }
    }));
  }

  public refreshToken(refresh_token: string) {
    return this.httpClient.post(`${environment.apiUrl}/api/auth/refresh`, {refresh_token}, {
      headers: {
        'Content-Type': "application/json"
      }
    }).pipe(map((res: any) => {
      if (res && 'token' in res) {
        this.tokenStorage.saveToken(res.token, this.rememberMe ? 'localStorage' : 'sessionStorage');
        if (res.refresh_token) {
          this.tokenStorage.saveRefreshToken(res.refresh_token, this.rememberMe ? 'localStorage' : 'sessionStorage');
        }
        return res.token;
      }
    }));
  }

  public register(datas: { "email": string, "firstname": string, "lastname": string, "plainPassword": string }) {
    return this.httpClient.post(`${environment.apiUrl}/api/public/users`, datas, {
      headers: {
        'Content-Type': "application/json"
      }
    }).pipe(map((res: any) => {
      if (res && 'token' in res) {
        this.tokenStorage.saveToken(res.token, 'localStorage');
        if (res.refresh_token) {
          this.tokenStorage.saveRefreshToken(res.refresh_token, 'localStorage');
        }
      }
    }));
  }


  public getMe() {
    /*if (this.networkService.getCurrentNetworkStatus() !== ConnectionStatus.Online) {
      this.indexedDbService.get('user').then((dbUser: any) => {
        if (dbUser) {
          this.user.next(dbUser);
          return of(dbUser);
        }
      });
    }*/

    this.initCalled = true;
    return this.httpClient.get<IUser>(`${environment.apiUrl}/api/me`, {
      headers: {
        'Content-Type': "application/json"
      }
    }).pipe(
      filter(user => !!user),
      map((user: IUser) => {
        user.subscriptionStatus = this.getSubscriptionStatus(user);
        return userMapper(user);
      }),
      tap(user => this.user.next(user)),
      tap(user => localStorage.setItem('gender', user.gender)),
      tap(user => {
        this.initialized = true;
        this.initializeDone.next(true);
      })
    );

  }

  public updateSubscription(datas: any) {
    return this.httpClient.put(`${environment.apiUrl}/api/users/additional_subscription/${this.user.getValue().id}`, datas, {
      headers: {
        'Content-Type': "application/json"
      }
    }).pipe(
      mergeMap(() => this.getMe())
    );
  }

  public resetPassword(email: string) {
    return this.httpClient.post(`${environment.apiUrl}/api/public/users/reset_password`, {email}, {
      headers: {
        'Content-Type': "application/json"
      }
    });
  }

  public newPassword(token: string, password: string) {
    return this.httpClient.put(`${environment.apiUrl}/api/public/users/update-password/${token}`, {plainPassword: password}, {
      headers: {
        'Content-Type': "application/json"
      }
    });
  }

  public logout() {
    /*return this.httpClient.post(`${environment.apiUrl}/api/auth/logout`, {}, {
      headers: {
        'Content-Type': "application/json"
      }
    }).subscribe(() => {
      this.tokenStorage.signOut();
      this.router.navigateByUrl('/');
      this.user.next(null);
    })*/
    this.tokenStorage.signOut();
    this.router.navigateByUrl('/');
    this.user.next(null);
  }

  public confirmEmail(data: { id: string, signature: string, expires: string, token: string }) {
    console.log(data);
    const queryParams = `signature=${encodeURIComponent(data.signature)}&id=${encodeURIComponent(data.id)}&expires=${encodeURIComponent(data.expires)}&token=${encodeURIComponent(data.token)}`;

    console.log(queryParams.toString());
    return this.httpClient.post(`${environment.apiUrl}/api/public/users/verify-email?${queryParams}`, {}, {
      headers: {
        'Content-Type': "application/json"
      }
    });

  }

  async facebooklogin() {
    const FACEBOOK_PERMISSIONS = ['email', 'user_birthday'];
    const result = await FacebookLogin.login({permissions: FACEBOOK_PERMISSIONS});

    if (result.accessToken && result.accessToken.userId) {
      console.log('facebook');
      console.log(result);
      this.loginWithSN('facebook', result.accessToken.token).subscribe((res) => {
        console.log(res);
      });
      // console.log(result.accessToken);
      // this.loadUserDataFb();
    } else if (result.accessToken && !result.accessToken.userId) {
      // this.getCurrentTokenFb();
      console.log('facebook');
      console.log(result);
      // console.log(result.accessToken);
    } else {
      // Login failed
    }
  }

  googleLogin() {
    GoogleAuth.signIn()
      .then((res) => {
        console.log('google');
        console.log(res);
        this.loginWithSN('google', res.authentication.idToken, {
          lastname: res.familyName,
          firstname: res.givenName,
          email: res.email
        }).subscribe((res) => {
          console.log(res);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  appleLogin() {
    let options: SignInWithAppleOptions = {
      clientId: environment.appId,
      redirectURI: environment.appleAuthRedirectURI,
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    };

    SignInWithApple.authorize(options)
      .then((result: SignInWithAppleResponse) => {
        // Handle user information
        // Validate token with server and create new session
        console.log('apple');
        console.log(result);
        this.loginWithSN('apple', result.response.authorizationCode, {
          lastname: result.response.familyName || '',
          firstname: result.response.givenName || '',
          email: result.response.email || '',
        }).subscribe((res) => {
          console.log(res);
        });
      })
      .catch(error => {
        // Handle error
        console.log(error);
      });
  }

  // redirectUrl ('/my-account/', '/my-account/orders/',
  // '/my-account/subscriptions/', '/my-account/my-account', '/cart', '/checkout', ...)
  generateAuthorizedUrl(redirectUrl: string) {
    return this.httpClient.get<IApiList<string>>(`${environment.apiUrl}/api/users/jwt-login-shop/${this.user.value.id}`)
      .pipe(
        map((res) => res['hydra:member'][0]),
        map(token => {
          const url = new URL(environment.shopUrl + '/wp/');
          url.searchParams.append('rest_route', '/simple-jwt-login/v1/autologin')
          url.searchParams.append('AUTH_KEY', environment.shopAuthKey);
          url.searchParams.append('JWT', token);
          url.searchParams.append('redirectUrl', redirectUrl);
          return url;
        })
      )
  }

  private getSubscriptionStatus(user: IUser): SubscriptionStatus {
    if (user.roles.includes('ROLE_USER_SUBSCRIBED')) {
      return SubscriptionStatus.SUBSCRIBED;
    } else {
      if (parseInt(user.trialLeftDays) > 0) {
        return SubscriptionStatus.TRIAL;
      } else {
        return SubscriptionStatus.EXPIRED;
      }
    }
  }


  /*async getCurrentTokenFb() {
    const result = await FacebookLogin.getCurrentAccessToken();

    if (result.accessToken) {
      this.token = result.accessToken;
      this.loadUserDataFb();
    } else {
      // Not logged in.
    }
  }

  async loadUserDataFb() {
    const url = `https://graph.facebook.com/${this.token.userId}?fields=id,name,picture.width(720),birthday,email&access_token=${this.token.token}`;
    this.http.get(url).subscribe(res => {
      this.user = res;
    });
  }

  async logoutFb() {
    await FacebookLogin.logout();
    this.user = null;
    this.token = null;
  }*/

}
