import { Component, EventEmitter, Input, Output } from '@angular/core';
import {Browser} from "@capacitor/browser";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'mbl-bottom-login-register',
  templateUrl: './bottom-login-register.component.html',
  styleUrls: ['./bottom-login-register.component.scss'],
})
export class BottomLoginRegisterComponent {

  @Input() isLogin: boolean = false;
  @Output() clickToRedirect: EventEmitter<any> = new EventEmitter();

  constructor() { }

  clickRedirect() {
    this.clickToRedirect.emit();
  }

  public redirectContact() {
      Browser.open({url: `${environment.shopUrl}/contact`});
    }

}
