// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://admin.main-bvxea6i-2sjhed34rnc6i.fr-3.platformsh.site',
  baseUrl: 'https://app.main-bvxea6i-2sjhed34rnc6i.fr-3.platformsh.site',
  hostname: 'app.main-bvxea6i-2sjhed34rnc6i.fr-3.platformsh.site',
  mercureApiUrl: 'https://mercure.main-bvxea6i-2sjhed34rnc6i.fr-3.platformsh.site/.well-known/mercure',
  mercureToken: 'eyJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiKiJdLCJzdWJzY3JpYmUiOlsiKiJdLCJwYXlsb2FkIjp7InVzZXIiOiJodHRwczovL2V4YW1wbGUuY29tL3VzZXJzL2R1bmdsYXMiLCJyZW1vdGVBZGRyIjoiMTI3LjAuMC4xIn19fQ.gq9JDa9Idjd27EwcLncyGVtmIpsw7UYe8qxO12Ie7wY',
  uploadsUrl: 'https://admin.main-bvxea6i-2sjhed34rnc6i.fr-3.platformsh.site',
  shopUrl: 'https://main-bvxea6i-2sjhed34rnc6i.fr-3.platformsh.site',
  shopAuthKey: 'dgiQAV7QjtfZIjYfATBMEcj0Izdcbqh84mqUuau26Y',
  shopConsumerKey: 'ck_4c22a7d7f8bc58f5a0f23c53d0170a8133948a4a',
  shopConsumerSecret: 'cs_5d6bc83043d9e8fb1d193d1e6d85a41795602ef6',
  appId: 'com.app.mybestlife',
  googleClientId: '804383422769-rni631lp4kms0m7l7u3t7bf7u014et5b.apps.googleusercontent.com',
  appleAuthRedirectURI: 'https://app.main-bvxea6i-2sjhed34rnc6i.fr-3.platformsh.site/start',
  contactPageLink: 'https://main-bvxea6i-2sjhed34rnc6i.fr-3.platformsh.site/contact/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
